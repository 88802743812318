import React from 'react';
import { useRole } from '../contexts/RoleContext';

type Props = {
  permission: string;
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

export function ProtectedComponent({ permission, fallback = null, children }: Props) {
  const { hasPermission } = useRole();

  if (!hasPermission(permission as any)) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}