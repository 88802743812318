// Get the base URL for API calls
const API_BASE = import.meta.env.DEV 
  ? 'http://localhost:8888/api'  // Local development
  : '/api';  // Production

console.log('Environment:', import.meta.env.DEV ? 'development' : 'production');
console.log('window.location.origin:', window.location.origin);
console.log('API_BASE configured as:', API_BASE);

const handleApiError = (error: any) => {
  console.error('API Error:', error);
  const message = error?.response?.data?.error || error.message || 'An error occurred';
  throw error;
};

export const aiAPI = {
  generateContentSuggestions: async (transcript: string) => {
    try {
      const url = `${API_BASE}/anthropic`;
      console.log('Making API request to:', url);
      console.log('Request payload:', { transcript });
      
      const response = await fetch(url, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ transcript }),
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('AI API error details:', {
          status: response.status,
          statusText: response.statusText,
          error: errorData,
          headers: Object.fromEntries(response.headers.entries()),
          url: response.url
        });
        throw new Error(errorData?.error || 'AI request failed');
      }
      
      const data = await response.json();
      console.log('API response data:', data);
      return data;
    } catch (error) {
      console.error('Full error details:', {
        error,
        stack: error instanceof Error ? error.stack : undefined
      });
      return handleApiError(error);
    }
  }
};