import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

type AuthContextType = {
  user: User | null;
  loading: boolean;
  signIn: (identifier: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  changePassword: (newPassword: string) => Promise<void>;
  forcePasswordChange: boolean;
  signUp: (email: string, password: string, username: string) => Promise<User | null>;
  resetPassword: (email: string) => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Simple session management from working version
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, []);

  const signIn = async (identifier: string, password: string) => {
    try {
      console.log('SignIn: Starting with identifier:', identifier);
      
      // Direct auth attempt without username lookup
      console.log('SignIn: Attempting auth with email');
      const { data, error } = await supabase.auth.signInWithPassword({
        email: identifier,
        password: password
      });

      if (error) {
        console.error('SignIn: Auth error:', error);
        throw error;
      }

      if (!data.user) {
        console.error('SignIn: No user returned');
        throw new Error('Authentication failed');
      }

      console.log('SignIn: Success, user data:', data.user);
      navigate('/');
      toast.success('Welcome back!');
    } catch (error) {
      console.error('SignIn: Final error:', error);
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('An unexpected error occurred');
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/login');
      toast.success('Signed out successfully');
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('An unexpected error occurred');
    }
  };

  const signUp = async (email: string, password: string, username: string) => {
    console.log('Starting signup process for:', { email, username });
    
    try {
      // First create the auth user
      console.log('Attempting to create auth user...');
      const { data: { user }, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { username }
        }
      });

      console.log('Auth signup response:', { user, error: signUpError });

      if (signUpError) {
        console.error('Auth signup error:', signUpError);
        throw signUpError;
      }
      if (!user) {
        console.error('No user returned from signup');
        throw new Error('No user returned from signup');
      }

      // Then create the profile
      console.log('Attempting to create profile for user:', user.id);
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .insert([
          { 
            user_id: user.id,
            username,
            role: 'editor',
            force_password_change: true
          }
        ])
        .select()
        .single();

      console.log('Profile creation response:', { profileData, error: profileError });

      if (profileError) {
        console.error('Profile creation error:', profileError);
        console.log('Attempting to clean up auth user...');
        try {
          await supabase.auth.admin.deleteUser(user.id);
          console.log('Auth user cleanup successful');
        } catch (cleanupError) {
          console.error('Failed to clean up auth user:', cleanupError);
        }
        throw profileError;
      }

      console.log('Signup process completed successfully');
      return user;
    } catch (error) {
      console.error('Signup process failed:', error);
      throw error;
    }
  };

  const changePassword = async (newPassword: string) => {
    try {
      console.log('Changing password...');
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      // Update force_password_change flag
      if (user) {
        console.log('Updating force_password_change flag...');
        const { error: updateError } = await supabase
          .from('users')
          .update({ 
            force_password_change: false,
            updated_at: new Date().toISOString()
          })
          .eq('id', user.id);

        if (updateError) throw updateError;
      }

      setForcePasswordChange(false);
      toast.success('Password updated successfully');
      
      // Instead of reloading, just update the state
      setLoading(false);
      navigate('/');
    } catch (error) {
      console.error('Password change error:', error);
      if (error instanceof Error) {
        throw new Error(error.message);
      }
      throw new Error('Failed to update password');
    }
  };

  const resetPassword = async (email: string) => {
    // Implementation of resetPassword method
  };

  console.log('AuthContext: Render state:', { 
    hasUser: !!user, 
    loading, 
    forcePasswordChange 
  });

  return (
    <AuthContext.Provider value={{
      user,
      loading,
      signIn,
      signOut,
      signUp,
      changePassword,
      forcePasswordChange,
      resetPassword
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}