import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { supabase } from '../lib/supabase';

type Permission = 
  | 'create_video'
  | 'edit_video'
  | 'review_video'
  | 'publish_video'
  | 'manage_users'
  | 'assign_tasks'
  | 'view_performance'
  | 'edit_metadata'
  | 'view_videos';  // Add new permission for viewing videos

type RolePermissions = {
  editor: Permission[];
  reviewer: Permission[];
  designer: Permission[];
  content_strategist: Permission[];
  social_media_manager: Permission[];
  admin: Permission[];
};

const rolePermissions: RolePermissions = {
  editor: [
    'create_video',
    'edit_video',
    'view_videos'  // Add view permission
  ],
  reviewer: [
    'review_video',
    'edit_metadata',
    'view_videos'  // Add view permission
  ],
  designer: [
    'create_video',
    'edit_video',
    'view_videos'  // Add view permission
  ],
  content_strategist: [
    'create_video',
    'edit_metadata',
    'view_performance',
    'view_videos'  // Add view permission
  ],
  social_media_manager: [
    'publish_video',
    'edit_metadata',
    'view_performance',
    'view_videos'  // Add view permission
  ],
  admin: [
    'create_video',
    'edit_video',
    'review_video',
    'publish_video',
    'manage_users',
    'assign_tasks',
    'view_performance',
    'edit_metadata',
    'view_videos'  // Add view permission
  ]
};

type RoleContextType = {
  hasPermission: (permission: Permission) => boolean;
  userRole: string | null;
};

const RoleContext = createContext<RoleContextType | undefined>(undefined);

export function useRole() {
  const context = useContext(RoleContext);
  if (context === undefined) {
    throw new Error('useRole must be used within a RoleProvider');
  }
  return context;
}

export function RoleProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [userRole, setUserRole] = useState<string | null>(null);
  
  useEffect(() => {
    if (user) {
      // Fetch role from public.users table
      supabase
        .from('users')
        .select('role')
        .eq('id', user.id)
        .single()
        .then(({ data, error }) => {
          if (!error && data) {
            setUserRole(data.role);
          }
        });
    }
  }, [user]);

  const hasPermission = (permission: Permission): boolean => {
    console.log('hasPermission check:', { permission, userRole, 
      permissions: rolePermissions[userRole as keyof typeof rolePermissions] 
    });
    
    if (!userRole) return false;
    return rolePermissions[userRole as keyof typeof rolePermissions]?.includes(permission) || false;
  };

  return (
    <RoleContext.Provider value={{ hasPermission, userRole }}>
      {children}
    </RoleContext.Provider>
  );
}