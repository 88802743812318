import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Loader2, Sparkles, Check, X, Plus, Send, Clock, Edit } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { ContentSuggestions } from './ContentSuggestions';
import toast from 'react-hot-toast';

type Props = {
  videoId: string;
  videoType: 'long-form' | 'short-form';
  transcript: string;
  onContentApproved?: () => void;
  onSubmitForReview?: () => Promise<void>;
};

type ContentOption = {
  id: string;
  title?: string;
  description?: string;
  text_overlay?: string;
  is_approved: boolean;
};

export const ContentApprovalSection = forwardRef(({ videoId, videoType, transcript, onContentApproved, onSubmitForReview }: Props, ref) => {
  const [titles, setTitles] = useState<ContentOption[]>([]);
  const [descriptions, setDescriptions] = useState<ContentOption[]>([]);
  const [thumbnailTexts, setThumbnailTexts] = useState<ContentOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [submittingForReview, setSubmittingForReview] = useState(false);
  const [addingContentType, setAddingContentType] = useState<'title' | 'description' | 'thumbnail' | null>(null);
  const [newContent, setNewContent] = useState('');
  const [contentReviewStatus, setContentReviewStatus] = useState<string>('not_started');

  useEffect(() => {
    loadContentOptions();
  }, [videoId]);

  // Expose loadContentOptions to parent
  useImperativeHandle(ref, () => ({
    loadContentOptions
  }));

  const loadContentOptions = async () => {
    try {
      setLoading(true);
      // First get the video's content review status
      const tableName = videoType === 'long-form' ? 'long_form_videos' : 'short_form_videos';
      const { data: videoData, error: videoError } = await supabase
        .from(tableName)
        .select('content_review_status')
        .eq('id', videoId)
        .single();

      if (videoError) throw videoError;
      
      const status = videoData?.content_review_status || 'not_started';
      setContentReviewStatus(status);

      // Always load all content options, but mark them with appropriate status
      const [{ data: allTitles }, { data: allDesc }, { data: allThumbs }] = await Promise.all([
        supabase
          .from('video_titles')
          .select('*')
          .eq('video_id', videoId)
          .eq('video_type', videoType),
        supabase
          .from('video_descriptions')
          .select('*')
          .eq('video_id', videoId)
          .eq('video_type', videoType),
        supabase
          .from('video_thumbnails')
          .select('*')
          .eq('video_id', videoId)
          .eq('video_type', videoType)
      ]);

      setTitles(allTitles || []);
      setDescriptions(allDesc || []);
      setThumbnailTexts(allThumbs || []);
    } catch (error) {
      console.error('Error loading content options:', error);
      toast.error('Failed to load content options');
    } finally {
      setLoading(false);
    }
  };

  const handleAddContent = async () => {
    if (!newContent.trim() || !addingContentType) return;

    try {
      let table: string;
      let data: any;

      switch (addingContentType) {
        case 'title':
          table = 'video_titles';
          data = { title: newContent };
          break;
        case 'description':
          table = 'video_descriptions';
          data = { description: newContent };
          break;
        case 'thumbnail':
          table = 'video_thumbnails';
          data = { text_overlay: newContent };
          break;
        default:
          return;
      }

      const { error } = await supabase
        .from(table)
        .insert([{
          video_id: videoId,
          video_type: videoType,
          ...data,
          is_approved: false
        }]);

      if (error) throw error;

      await loadContentOptions();
      setNewContent('');
      setAddingContentType(null);
      toast.success('Content option added successfully');
    } catch (error) {
      console.error('Error adding content:', error);
      toast.error('Failed to add content option');
    }
  };

  const handleSubmitForReview = async () => {
    if (!onSubmitForReview) return;

    setSubmittingForReview(true);
    try {
      // Update the content review status first
      const tableName = videoType === 'long-form' ? 'long_form_videos' : 'short_form_videos';
      const { error: statusError } = await supabase
        .from(tableName)
        .update({ content_review_status: 'pending_review' })
        .eq('id', videoId);

      if (statusError) throw statusError;

      // Then call the parent's onSubmitForReview
      await onSubmitForReview();
      setContentReviewStatus('pending_review');
      toast.success('Content submitted for review');
    } catch (error) {
      console.error('Error submitting for review:', error);
      toast.error('Failed to submit for review');
    } finally {
      setSubmittingForReview(false);
    }
  };

  const handleSuggestionsGenerated = async () => {
    await loadContentOptions();
    toast.success('Content suggestions added successfully');
  };

  const renderContentOption = (option: ContentOption) => {
    const content = option.title || option.description || option.text_overlay;
    return (
      <div key={option.id} className="p-4 bg-gray-50 rounded-lg">
        <div className="flex items-center justify-between">
          <p className="text-gray-900">{content}</p>
          {contentReviewStatus === 'reviewed' && option.is_approved ? (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
              <Check className="h-3 w-3 mr-1" />
              Approved
            </span>
          ) : contentReviewStatus === 'pending_review' ? (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
              <Clock className="h-3 w-3 mr-1" />
              Pending Approval
            </span>
          ) : (
            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
              <Edit className="h-3 w-3 mr-1" />
              Draft
            </span>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="h-8 w-8 text-indigo-600 animate-spin" />
      </div>
    );
  }

  const isReviewed = contentReviewStatus === 'reviewed';
  const isPendingReview = contentReviewStatus === 'pending_review';
  const hasContent = titles.length > 0 || descriptions.length > 0 || thumbnailTexts.length > 0;

  return (
    <div className="space-y-8">
      {/* Content Creation Section */}
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Content Options</h2>
          {!isReviewed && (
            <div className="flex items-center space-x-4">
              {transcript && !isPendingReview && (
                <ContentSuggestions
                  videoId={videoId}
                  videoType={videoType}
                  transcript={transcript}
                  onSuggestionsGenerated={handleSuggestionsGenerated}
                />
              )}
              {hasContent && !isPendingReview && (
                <button
                  onClick={handleSubmitForReview}
                  disabled={submittingForReview}
                  className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
                >
                  {submittingForReview ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span>Submitting...</span>
                    </>
                  ) : (
                    <>
                      <Send className="h-4 w-4" />
                      <span>Submit for Review</span>
                    </>
                  )}
                </button>
              )}
            </div>
          )}
        </div>

        {/* Content Options */}
        <div className="space-y-6">
          {/* Title Options */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-md font-medium text-gray-900">Title Options</h3>
              {!isReviewed && !isPendingReview && (
                <button
                  onClick={() => setAddingContentType('title')}
                  className="flex items-center space-x-2 px-3 py-1 text-sm bg-indigo-50 text-indigo-600 rounded-md hover:bg-indigo-100"
                >
                  <Plus className="h-4 w-4" />
                  <span>Add Title</span>
                </button>
              )}
            </div>
            <div className="space-y-4">
              {titles.map(title => renderContentOption(title))}
              {titles.length === 0 && (
                <p className="text-center text-gray-500 py-4">No title options available</p>
              )}
            </div>
          </div>

          {/* Description Options */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-md font-medium text-gray-900">Description Options</h3>
              {!isReviewed && !isPendingReview && (
                <button
                  onClick={() => setAddingContentType('description')}
                  className="flex items-center space-x-2 px-3 py-1 text-sm bg-indigo-50 text-indigo-600 rounded-md hover:bg-indigo-100"
                >
                  <Plus className="h-4 w-4" />
                  <span>Add Description</span>
                </button>
              )}
            </div>
            <div className="space-y-4">
              {descriptions.map(desc => renderContentOption(desc))}
              {descriptions.length === 0 && (
                <p className="text-center text-gray-500 py-4">No description options available</p>
              )}
            </div>
          </div>

          {/* Thumbnail Text Options */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-md font-medium text-gray-900">Thumbnail Text Options</h3>
              {!isReviewed && !isPendingReview && (
                <button
                  onClick={() => setAddingContentType('thumbnail')}
                  className="flex items-center space-x-2 px-3 py-1 text-sm bg-indigo-50 text-indigo-600 rounded-md hover:bg-indigo-100"
                >
                  <Plus className="h-4 w-4" />
                  <span>Add Thumbnail Text</span>
                </button>
              )}
            </div>
            <div className="space-y-4">
              {thumbnailTexts.map(text => renderContentOption(text))}
              {thumbnailTexts.length === 0 && (
                <p className="text-center text-gray-500 py-4">No thumbnail text options available</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add Content Modal */}
      {addingContentType && !isReviewed && !isPendingReview && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-900">
                Add {addingContentType.charAt(0).toUpperCase() + addingContentType.slice(1)}
              </h3>
              <button
                onClick={() => setAddingContentType(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-4">
              <textarea
                value={newContent}
                onChange={(e) => setNewContent(e.target.value)}
                className="w-full h-32 p-3 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                placeholder={`Enter ${addingContentType} text...`}
              />

              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setAddingContentType(null)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={handleAddContent}
                  disabled={!newContent.trim()}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 disabled:opacity-50"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});