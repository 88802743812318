import { supabase } from './supabase';
import { getEnvVar } from './config';

class YouTubeService {
  private baseUrl = 'https://www.googleapis.com/youtube/v3';
  private authUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  private tokenUrl = 'https://oauth2.googleapis.com/token';
  private scopes = [
    'https://www.googleapis.com/auth/youtube.readonly',
    'https://www.googleapis.com/auth/youtube.force-ssl'
  ];

  private getRedirectUri(): string {
    const currentUrl = new URL(window.location.href);
    return `${currentUrl.protocol}//${currentUrl.host}/auth/youtube/callback`;
  }

  private async getConfig() {
    const [apiKey, clientId, clientSecret] = await Promise.all([
      getEnvVar('VITE_YOUTUBE_API_KEY'),
      getEnvVar('VITE_YOUTUBE_CLIENT_ID'),
      getEnvVar('VITE_YOUTUBE_CLIENT_SECRET')
    ]);

    return { apiKey, clientId, clientSecret };
  }

  async initiateOAuth() {
    try {
      const { clientId } = await this.getConfig();
      if (!clientId) {
        throw new Error('YouTube Client ID is not configured');
      }

      // Generate a random state value
      const state = Math.random().toString(36).substring(7);
      
      // Store state in localStorage for verification
      localStorage.setItem('youtube_oauth_state', state);

      // Build the authorization URL
      const params = new URLSearchParams({
        client_id: clientId,
        redirect_uri: this.getRedirectUri(),
        response_type: 'code',
        scope: this.scopes.join(' '),
        access_type: 'offline',
        state: state,
        prompt: 'consent'
      });

      const authUrl = `${this.authUrl}?${params.toString()}`;
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error initiating YouTube auth:', error);
      throw error;
    }
  }

  async handleCallback(code: string, state: string) {
    try {
      const { clientId, clientSecret } = await this.getConfig();
      if (!clientId || !clientSecret) {
        throw new Error('Missing required OAuth credentials');
      }

      // Verify state
      const savedState = localStorage.getItem('youtube_oauth_state');
      if (!savedState || state !== savedState) {
        throw new Error('Invalid state parameter');
      }

      // Exchange code for tokens
      const response = await fetch(this.tokenUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          code,
          client_id: clientId,
          client_secret: clientSecret,
          redirect_uri: this.getRedirectUri(),
          grant_type: 'authorization_code'
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(`Token exchange failed: ${error.error_description || error.error || response.statusText}`);
      }

      const tokens = await response.json();

      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No authenticated user found');

      // Store tokens
      const { error: tokenError } = await supabase
        .from('user_oauth_tokens')
        .insert({
          user_id: user.id,
          provider: 'youtube',
          access_token: tokens.access_token,
          refresh_token: tokens.refresh_token,
          expires_at: new Date(Date.now() + tokens.expires_in * 1000).toISOString()
        });

      if (tokenError) throw tokenError;

      // Clear state after successful token exchange
      localStorage.removeItem('youtube_oauth_state');

      return tokens;
    } catch (error) {
      console.error('OAuth callback error:', error);
      throw error;
    }
  }

  private async getAccessToken(): Promise<string> {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('User not authenticated');

    // Get the most recent token
    const { data: tokens, error } = await supabase
      .from('user_oauth_tokens')
      .select('*')
      .eq('user_id', user.id)
      .eq('provider', 'youtube')
      .order('created_at', { ascending: false })
      .limit(1)
      .single();

    if (error) throw error;
    if (!tokens) throw new Error('No YouTube tokens found. Please connect your YouTube account.');

    // TODO: Handle token refresh if expired
    return tokens.access_token;
  }

  async getVideoDetails(videoId: string) {
    try {
      const { apiKey } = await this.getConfig();
      if (!apiKey) throw new Error('YouTube API key is not configured');

      const response = await fetch(
        `${this.baseUrl}/videos?part=snippet,contentDetails,status&id=${videoId}&key=${apiKey}`
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(`YouTube API error: ${error.error?.message || response.statusText}`);
      }

      const { items } = await response.json();
      if (!items || items.length === 0) {
        throw new Error('Video not found');
      }

      return items[0];
    } catch (error) {
      console.error('Error fetching video details:', error);
      throw error;
    }
  }

  async updateVideo(videoId: string, updates: {
    title?: string;
    description?: string;
    privacyStatus?: 'private' | 'unlisted' | 'public';
  }) {
    try {
      const { apiKey } = await this.getConfig();
      if (!apiKey) throw new Error('YouTube API key is not configured');

      // First get the current video details
      const video = await this.getVideoDetails(videoId);
      
      // Prepare the update payload
      const payload = {
        id: videoId,
        snippet: {
          ...video.snippet,
          title: updates.title ?? video.snippet.title,
          description: updates.description ?? video.snippet.description,
          categoryId: video.snippet.categoryId
        },
        status: {
          ...video.status,
          privacyStatus: updates.privacyStatus ?? video.status?.privacyStatus
        }
      };

      const response = await fetch(
        `${this.baseUrl}/videos?part=snippet,status&key=${apiKey}`,
        {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${await this.getAccessToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(`YouTube API error: ${error.error?.message || response.statusText}`);
      }

      return response.json();
    } catch (error) {
      console.error('Error updating video:', error);
      throw error;
    }
  }

  async getChannelVideos(maxResults: number = 10) {
    try {
      const { apiKey } = await this.getConfig();
      if (!apiKey) throw new Error('YouTube API key is not configured');

      const accessToken = await this.getAccessToken();
      
      // First get the authenticated user's channel
      const channelResponse = await fetch(
        `${this.baseUrl}/channels?part=contentDetails&mine=true&key=${apiKey}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (!channelResponse.ok) {
        const error = await channelResponse.json();
        throw new Error(`YouTube API error: ${error.error?.message || channelResponse.statusText}`);
      }

      const channelData = await channelResponse.json();
      if (!channelData.items?.[0]) {
        throw new Error('No channel found for authenticated user');
      }

      const uploadsPlaylistId = channelData.items[0].contentDetails.relatedPlaylists.uploads;

      // Get videos from uploads playlist
      const playlistResponse = await fetch(
        `${this.baseUrl}/playlistItems?part=snippet,contentDetails&playlistId=${uploadsPlaylistId}&maxResults=${maxResults}&key=${apiKey}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (!playlistResponse.ok) {
        const error = await playlistResponse.json();
        throw new Error(`YouTube API error: ${error.error?.message || playlistResponse.statusText}`);
      }

      const playlistData = await playlistResponse.json();
      if (!playlistData.items?.length) {
        return [];
      }

      // Get full video details
      const videoIds = playlistData.items
        .map((item: any) => item.contentDetails.videoId)
        .join(',');

      const videosResponse = await fetch(
        `${this.baseUrl}/videos?part=snippet,contentDetails,status&id=${videoIds}&key=${apiKey}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );

      if (!videosResponse.ok) {
        const error = await videosResponse.json();
        throw new Error(`YouTube API error: ${error.error?.message || videosResponse.statusText}`);
      }

      return (await videosResponse.json()).items || [];
    } catch (error) {
      console.error('Error fetching channel videos:', error);
      throw error;
    }
  }
}

export const youtube = new YouTubeService();