import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { youtube } from '../lib/youtube';
import toast from 'react-hot-toast';

export default function YouTubeCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Get URL parameters
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        const error = searchParams.get('error');

        if (error) {
          console.error('Auth error:', error);
          setError(error);
          return;
        }

        if (!code || !state) {
          console.error('Missing parameters:', { code, state });
          setError('Missing required OAuth parameters');
          return;
        }

        // Verify state
        const savedState = localStorage.getItem('youtube_oauth_state');
        if (!savedState || state !== savedState) {
          setError('Invalid state parameter');
          return;
        }

        // Exchange code for tokens
        await youtube.handleCallback(code, state);
        
        // Only clear state after successful token exchange
        localStorage.removeItem('youtube_oauth_state');
        
        toast.success('Successfully connected to YouTube');
        navigate('/api-tests');
      } catch (error) {
        console.error('Error handling callback:', error);
        setError(error instanceof Error ? error.message : 'Failed to complete authentication');
      }
    };

    handleCallback();
  }, [searchParams, navigate]);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Authentication Error</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => navigate('/api-tests')}
            className="w-full px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
          >
            Return to Tests
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="flex flex-col items-center">
        <Loader2 className="h-8 w-8 text-indigo-600 animate-spin" />
        <p className="mt-4 text-gray-600">Completing YouTube authentication...</p>
      </div>
    </div>
  );
}