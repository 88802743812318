import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Video, FileVideo, Mic, CheckSquare, BarChart2, LogOut, User, Settings } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useRole } from '../contexts/RoleContext';
import { ProtectedComponent } from './ProtectedComponent';
import { TaskNotificationCenter } from './TaskNotificationCenter';

const Navigation = () => {
  const location = useLocation();
  const { signOut, user } = useAuth();
  const { userRole } = useRole();
  const isAdmin = user?.user_metadata?.role === 'admin';

  const isActive = (path: string) => location.pathname === path;

  return (
    <nav className="bg-white shadow-sm">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center space-x-8">
            <Link to="/" className="flex items-center space-x-2">
              <Video className="h-6 w-6 text-indigo-600" />
              <span className="font-semibold text-gray-900">Video Workflow</span>
            </Link>
            
            <div className="hidden md:flex items-center space-x-4">
              <ProtectedComponent permission="view_videos">
                <Link
                  to="/long-form"
                  className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium ${
                    isActive('/long-form')
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <Video className="h-4 w-4" />
                  <span>Long-Form</span>
                </Link>

                <Link
                  to="/short-form"
                  className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium ${
                    isActive('/short-form')
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <FileVideo className="h-4 w-4" />
                  <span>Short-Form</span>
                </Link>
              </ProtectedComponent>

              <Link
                to="/podcasts"
                className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium ${
                  isActive('/podcasts')
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Mic className="h-4 w-4" />
                <span>Podcasts</span>
              </Link>

              <Link
                to="/tasks"
                className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium ${
                  isActive('/tasks')
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <CheckSquare className="h-4 w-4" />
                <span>Tasks</span>
              </Link>

              <ProtectedComponent permission="view_performance">
                <Link
                  to="/performance"
                  className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium ${
                    isActive('/performance')
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-600 hover:bg-gray-100'
                  }`}
                >
                  <BarChart2 className="h-4 w-4" />
                  <span>Performance</span>
                </Link>
              </ProtectedComponent>
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <div className="text-sm text-gray-500">
              Role: {userRole?.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </div>
            <TaskNotificationCenter />
            <Link
              to="/account"
              className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium ${
                isActive('/account')
                  ? 'bg-indigo-100 text-indigo-700'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              <User className="h-4 w-4" />
              <span>Account</span>
            </Link>
            <ProtectedComponent permission="manage_users">
              <Link
                to="/api-tests"
                className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium ${
                  isActive('/api-tests')
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-600 hover:bg-gray-100'
                }`}
              >
                <Settings className="h-4 w-4" />
                <span>API Tests</span>
              </Link>
            </ProtectedComponent>
            {isAdmin && (
              <Link 
                to="/admin"
                className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium"
              >
                Admin
              </Link>
            )}
            <button
              onClick={() => signOut()}
              className="flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-100"
            >
              <LogOut className="h-4 w-4" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;