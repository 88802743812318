import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

export type Video = {
  id: string;
  title: string;
  status: string;
  frameio_link?: string;
  youtube_link?: string;
  transcript?: string;
  assigned_editor?: string;
  assigned_reviewer?: string;
  scheduled_publish_date?: string;
  platform?: string;
  platform_metadata?: any;
  duration?: string;
  created_at: string;
  updated_at: string;
  editor?: {
    full_name: string;
  };
  reviewer?: {
    full_name: string;
  };
};

export function useVideoDetail(videoId: string, type: 'long-form' | 'short-form') {
  const [video, setVideo] = useState<Video | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isDeleted, setIsDeleted] = useState(false);

  // Determine table name based on type
  const tableName = type === 'short-form' ? 'short_form_videos' : 'long_form_videos';

  const fetchVideo = async () => {
    try {
      console.log('useVideoDetail - Fetching video:', { videoId, tableName });
      
      // First check if the video exists
      const { data: existingVideo, error: checkError } = await supabase
        .from(tableName)
        .select('id')
        .eq('id', videoId)
        .single();

      if (checkError) {
        throw new Error('Video not found');
      }

      // Fetch full video details
      const { data, error: fetchError } = await supabase
        .from(tableName)
        .select(`
          *,
          editor:assigned_editor(full_name),
          reviewer:assigned_reviewer(full_name)
        `)
        .eq('id', videoId)
        .single();

      if (fetchError) throw fetchError;
      if (!data) throw new Error('Video not found');

      console.log('useVideoDetail - Found video:', data);
      setVideo(data);
      return data;
    } catch (err) {
      console.error('useVideoDetail - Error fetching video:', err);
      setError(err instanceof Error ? err : new Error('Failed to fetch video details'));
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Set up real-time subscription
  useEffect(() => {
    fetchVideo();

    const subscription = supabase
      .channel(`video_${videoId}`)
      .on('postgres_changes', 
        { 
          event: '*', 
          schema: 'public', 
          table: tableName, 
          filter: `id=eq.${videoId}` 
        },
        (payload) => {
          console.log('useVideoDetail - Received real-time update:', payload);
          
          if (payload.eventType === 'DELETE') {
            setIsDeleted(true);
          } else if (payload.eventType === 'UPDATE') {
            setVideo(payload.new as Video);
          }
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [videoId, type, tableName]);

  const updateStatus = async (newStatus: string) => {
    if (!video) {
      throw new Error('No video loaded');
    }

    console.log('useVideoDetail - Starting status update:', {
      videoId,
      currentStatus: video.status,
      newStatus,
      tableName
    });

    try {
      console.log('useVideoDetail - Sending update request to Supabase:', {
        table: tableName,
        id: videoId,
        newStatus
      });

      const { data, error } = await supabase
        .from(tableName)
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', videoId)
        .select()
        .single();

      if (error) {
        console.error('useVideoDetail - Database error:', error);
        throw error;
      }

      if (!data) {
        console.error('useVideoDetail - No data returned from update');
        throw new Error('No data returned from update');
      }

      console.log('useVideoDetail - Update successful:', data);
      setVideo(data);

    } catch (err) {
      console.error('useVideoDetail - Update failed:', err);
      throw err instanceof Error ? err : new Error('Failed to update video status');
    }
  };

  const updateLinks = async (updates: { frameioLink?: string; youtubeLink?: string }) => {
    if (!video) {
      throw new Error('No video loaded');
    }

    console.log('useVideoDetail - Starting links update:', {
      videoId,
      updates,
      tableName
    });

    try {
      const { data, error } = await supabase
        .from(tableName)
        .update({
          frameio_link: updates.frameioLink,
          youtube_link: updates.youtubeLink,
          updated_at: new Date().toISOString()
        })
        .eq('id', videoId)
        .select()
        .single();

      if (error) {
        console.error('useVideoDetail - Database error:', error);
        throw error;
      }

      if (!data) {
        console.error('useVideoDetail - No data returned from update');
        throw new Error('No data returned from update');
      }

      console.log('useVideoDetail - Links update successful:', data);
      setVideo(data);

    } catch (err) {
      console.error('useVideoDetail - Links update failed:', err);
      throw err instanceof Error ? err : new Error('Failed to update video links');
    }
  };

  const deleteVideo = async () => {
    if (!video) {
      throw new Error('No video loaded');
    }

    console.log('useVideoDetail - Starting video deletion:', {
      videoId,
      tableName
    });

    try {
      // First, delete any associated comments
      const { error: commentsError } = await supabase
        .from('video_comments')
        .delete()
        .eq('video_id', videoId);

      if (commentsError) {
        console.error('useVideoDetail - Error deleting comments:', commentsError);
        throw commentsError;
      }

      // Then delete the video
      const { error: videoError } = await supabase
        .from(tableName)
        .delete()
        .eq('id', videoId);

      if (videoError) {
        console.error('useVideoDetail - Error deleting video:', videoError);
        throw videoError;
      }

      console.log('useVideoDetail - Video deleted successfully');
      setIsDeleted(true);

    } catch (err) {
      console.error('useVideoDetail - Delete failed:', err);
      throw err instanceof Error ? err : new Error('Failed to delete video');
    }
  };

  return {
    video,
    loading,
    error,
    isDeleted,
    updateStatus,
    updateLinks,
    deleteVideo,
    refreshVideo: fetchVideo
  };
}