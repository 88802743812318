import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from './AuthContext';
import { useRole } from './RoleContext';
import toast from 'react-hot-toast';

type Notification = {
  id: string;
  title: string;
  message: string;
  type: string;
  is_read: boolean;
  created_at: string;
};

type NotificationContextType = {
  notifications: Notification[];
  unreadCount: number;
  markAsRead: (notificationId: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
};

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export function useNotifications() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
}

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { user } = useAuth();
  const { userRole } = useRole();

  const unreadCount = notifications.filter(n => !n.is_read).length;

  const markAsRead = async (notificationId: string) => {
    try {
      const notification = notifications.find(n => n.id === notificationId);
      if (!notification) return;

      const table = 'user_id' in notification ? 'task_notifications' : 'role_notifications';
      
      const { error } = await supabase
        .from(table)
        .update({ is_read: true })
        .eq('id', notificationId);

      if (error) throw error;

      setNotifications(prev =>
        prev.map(n => n.id === notificationId ? { ...n, is_read: true } : n)
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
      toast.error('Failed to mark notification as read');
    }
  };

  const markAllAsRead = async () => {
    try {
      const { error: taskError } = await supabase
        .from('task_notifications')
        .update({ is_read: true })
        .eq('user_id', user?.id);

      if (taskError) throw taskError;

      const { error: roleError } = await supabase
        .from('role_notifications')
        .update({ is_read: true })
        .eq('role', userRole);

      if (roleError) throw roleError;

      setNotifications(prev => prev.map(n => ({ ...n, is_read: true })));
      toast.success('All notifications marked as read');
    } catch (error) {
      console.error('Error marking all notifications as read:', error);
      toast.error('Failed to mark all notifications as read');
    }
  };

  useEffect(() => {
    if (!user || !userRole) return;

    const fetchNotifications = async () => {
      try {
        const { data: taskNotifications, error: taskError } = await supabase
          .from('task_notifications')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (taskError) throw taskError;

        const { data: roleNotifications, error: roleError } = await supabase
          .from('role_notifications')
          .select('*')
          .eq('role', userRole)
          .order('created_at', { ascending: false });

        if (roleError) throw roleError;

        const allNotifications = [
          ...(taskNotifications || []),
          ...(roleNotifications || [])
        ].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

        setNotifications(allNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        toast.error('Failed to load notifications');
      }
    };

    fetchNotifications();

    const taskSubscription = supabase
      .channel('task_notifications')
      .on('postgres_changes', 
        { event: '*', schema: 'public', table: 'task_notifications', filter: `user_id=eq.${user.id}` },
        () => {
          fetchNotifications().catch(error => {
            console.error('Error in subscription update:', error);
          });
        }
      )
      .subscribe();

    const roleSubscription = supabase
      .channel('role_notifications')
      .on('postgres_changes', 
        { event: '*', schema: 'public', table: 'role_notifications', filter: `role=eq.${userRole}` },
        () => {
          fetchNotifications().catch(error => {
            console.error('Error in subscription update:', error);
          });
        }
      )
      .subscribe();

    return () => {
      taskSubscription.unsubscribe();
      roleSubscription.unsubscribe();
    };
  }, [user, userRole]);

  return (
    <NotificationContext.Provider value={{ notifications, unreadCount, markAsRead, markAllAsRead }}>
      {children}
    </NotificationContext.Provider>
  );
}