import { aiAPI } from './api';
import { supabase } from './supabase';

type ContentSuggestions = {
  titles: string[];
  descriptions: string[];
  thumbnailTexts: string[];
};

class AIService {
  async generateContentSuggestions(transcript: string): Promise<ContentSuggestions> {
    try {
      const suggestions = await aiAPI.generateContentSuggestions(transcript);
      
      // Validate response format
      if (!suggestions.titles || !suggestions.descriptions || !suggestions.thumbnailTexts) {
        throw new Error('Invalid suggestions format from AI service');
      }

      return {
        titles: suggestions.titles || [],
        descriptions: suggestions.descriptions || [],
        thumbnailTexts: suggestions.thumbnailTexts || []
      };
    } catch (error) {
      console.error('Error generating content suggestions:', error);
      throw error;
    }
  }

  async saveContentSuggestions(
    videoId: string,
    videoType: 'long_form' | 'short_form',
    suggestions: ContentSuggestions
  ) {
    try {
      // Start a batch insert for titles
      const { error: titleError } = await supabase
        .from('video_titles')
        .insert(suggestions.titles.map(title => ({
          video_id: videoId,
          video_type: videoType,
          title,
          is_approved: false
        })));

      if (titleError) throw titleError;

      // Batch insert for descriptions
      const { error: descError } = await supabase
        .from('video_descriptions')
        .insert(suggestions.descriptions.map(description => ({
          video_id: videoId,
          video_type: videoType,
          description,
          is_approved: false
        })));

      if (descError) throw descError;

      // Batch insert for thumbnail texts
      const { error: thumbError } = await supabase
        .from('video_thumbnails')
        .insert(suggestions.thumbnailTexts.map(text => ({
          video_id: videoId,
          video_type: videoType,
          text_overlay: text,
          is_approved: false
        })));

      if (thumbError) throw thumbError;

    } catch (error) {
      console.error('Error saving content suggestions:', error);
      throw error;
    }
  }
}

// Create and export singleton instance
export const ai = new AIService();