import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

type Props = {
  currentStatus: string;
  onClose: () => void;
  onUpdate: (newStatus: string) => Promise<void>;
};

// Define valid status transitions
const statusFlow = {
  draft: ['editing'],
  editing: ['ready_for_review'],
  ready_for_review: ['revisions_needed', 'edit_approved'],
  revisions_needed: ['editing'],
  edit_approved: ['needs_title_thumbnail_description'],
  needs_title_thumbnail_description: ['ready_for_posting'],
  ready_for_posting: ['scheduled'],
  scheduled: ['published'],
  published: []
};

// Status display labels
const statusLabels: Record<string, string> = {
  draft: 'Draft',
  editing: 'Editing',
  ready_for_review: 'Ready for Review',
  revisions_needed: 'Revisions Needed',
  edit_approved: 'Edit Approved',
  needs_title_thumbnail_description: 'Needs Title/Thumbnail/Description',
  ready_for_posting: 'Ready for Posting',
  scheduled: 'Scheduled',
  published: 'Published'
};

export function StatusUpdateModal({ currentStatus, onClose, onUpdate }: Props) {
  const [selectedStatus, setSelectedStatus] = useState('');
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const availableStatuses = statusFlow[currentStatus as keyof typeof statusFlow] || [];

  // Debug logs for status flow
  useEffect(() => {
    console.log('StatusUpdateModal - Current Status:', currentStatus);
    console.log('StatusUpdateModal - Available Transitions:', availableStatuses);
  }, [currentStatus, availableStatuses]);

  const handleUpdate = async () => {
    if (!selectedStatus) return;

    console.log('StatusUpdateModal - Attempting to update status:', {
      from: currentStatus,
      to: selectedStatus,
      availableTransitions: availableStatuses
    });

    setUpdating(true);
    setError(null);
    try {
      await onUpdate(selectedStatus);
      console.log('StatusUpdateModal - Status update successful:', selectedStatus);
      onClose();
    } catch (err) {
      console.error('StatusUpdateModal - Update failed:', err);
      setError('Failed to update status. Please try again.');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-900">Update Status</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Current Status
            </label>
            <div className="px-3 py-2 bg-gray-50 rounded-md text-gray-700">
              {statusLabels[currentStatus]}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              New Status
            </label>
            <select
              value={selectedStatus}
              onChange={(e) => {
                console.log('StatusUpdateModal - Selected new status:', e.target.value);
                setSelectedStatus(e.target.value);
              }}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Select new status</option>
              {availableStatuses.map((status) => (
                <option key={status} value={status}>
                  {statusLabels[status]}
                </option>
              ))}
            </select>
          </div>

          {error && (
            <div className="text-red-500 text-sm">
              {error}
            </div>
          )}

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleUpdate}
              disabled={!selectedStatus || updating}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {updating ? 'Updating...' : 'Update Status'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}