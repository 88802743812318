import React, { useState } from 'react';
import { MessageSquare, Loader2, MoreVertical, Trash, Edit } from 'lucide-react';
import { useVideoComments, Comment } from '../hooks/useVideoComments';
import { useRole } from '../contexts/RoleContext';
import { useAuth } from '../contexts/AuthContext';
import { formatDistanceToNow } from 'date-fns';
import toast from 'react-hot-toast';

type Props = {
  videoId: string;
};

export function CommentSection({ videoId }: Props) {
  const { comments, loading, error, addComment, updateComment, deleteComment } = useVideoComments(videoId);
  const [newComment, setNewComment] = useState('');
  const [editingComment, setEditingComment] = useState<string | null>(null);
  const [editText, setEditText] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const { hasPermission } = useRole();
  const { user } = useAuth();

  const handleSubmit = async () => {
    if (!newComment.trim()) return;

    setSubmitting(true);
    try {
      await addComment(newComment.trim());
      setNewComment('');
      toast.success('Comment added successfully');
    } catch (error) {
      console.error('Error adding comment:', error);
      toast.error('Failed to add comment');
    } finally {
      setSubmitting(false);
    }
  };

  const handleEdit = async (commentId: string) => {
    if (!editText.trim()) return;

    try {
      await updateComment(commentId, editText.trim());
      setEditingComment(null);
      toast.success('Comment updated successfully');
    } catch (error) {
      console.error('Error updating comment:', error);
      toast.error('Failed to update comment');
    }
  };

  const handleDelete = async (commentId: string) => {
    try {
      await deleteComment(commentId);
      toast.success('Comment deleted successfully');
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    }
  };

  const canModerateComments = hasPermission('manage_users');

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-6">
        <div className="text-red-600">Failed to load comments</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">Comments</h2>
        <MessageSquare className="h-5 w-5 text-gray-500" />
      </div>

      {loading ? (
        <div className="flex justify-center py-4">
          <Loader2 className="h-6 w-6 text-indigo-600 animate-spin" />
        </div>
      ) : (
        <div className="space-y-4">
          {comments.map((comment) => (
            <div key={comment.id} className="p-3 bg-gray-50 rounded-lg">
              {editingComment === comment.id ? (
                <div className="space-y-2">
                  <textarea
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    className="w-full p-2 border rounded-md text-sm"
                    rows={3}
                  />
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => setEditingComment(null)}
                      className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleEdit(comment.id)}
                      className="px-3 py-1 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="flex justify-between items-start">
                    <p className="text-sm text-gray-900">{comment.content}</p>
                    {(comment.user_id === user?.id || canModerateComments) && (
                      <div className="relative group">
                        <button className="p-1 rounded-full hover:bg-gray-200">
                          <MoreVertical className="h-4 w-4 text-gray-500" />
                        </button>
                        <div className="absolute right-0 mt-1 w-32 bg-white rounded-md shadow-lg border border-gray-200 hidden group-hover:block">
                          {comment.user_id === user?.id && (
                            <button
                              onClick={() => {
                                setEditingComment(comment.id);
                                setEditText(comment.content);
                              }}
                              className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            >
                              <Edit className="h-4 w-4 mr-2" />
                              Edit
                            </button>
                          )}
                          {(comment.user_id === user?.id || canModerateComments) && (
                            <button
                              onClick={() => handleDelete(comment.id)}
                              className="w-full px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100 flex items-center"
                            >
                              <Trash className="h-4 w-4 mr-2" />
                              Delete
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mt-1 flex items-center space-x-2 text-xs text-gray-500">
                    <span>{comment.user.full_name}</span>
                    <span>•</span>
                    <span>{formatDistanceToNow(new Date(comment.created_at), { addSuffix: true })}</span>
                  </div>
                </>
              )}
            </div>
          ))}

          {comments.length === 0 && (
            <p className="text-center text-gray-500 py-4">No comments yet</p>
          )}
        </div>
      )}

      <div className="mt-4">
        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          className="w-full p-2 border rounded-md text-sm"
          placeholder="Add a comment..."
          rows={3}
          disabled={submitting}
        />
        <button
          onClick={handleSubmit}
          disabled={submitting || !newComment.trim()}
          className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-md text-sm hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {submitting ? 'Posting...' : 'Post Comment'}
        </button>
      </div>
    </div>
  );
}