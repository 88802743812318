import React, { useState } from 'react';
import { Loader2, Sparkles } from 'lucide-react';
import { ai } from '../lib/ai';
import toast from 'react-hot-toast';
import { AISuggestionsModal } from './AISuggestionsModal';

type Props = {
  videoId: string;
  videoType: 'long_form' | 'short_form';
  transcript: string;
  onSuggestionsGenerated?: () => void;
};

export function ContentSuggestions({ videoId, videoType, transcript, onSuggestionsGenerated }: Props) {
  const [loading, setLoading] = useState(false);
  const [showAIModal, setShowAIModal] = useState(false);
  const [aiSuggestions, setAiSuggestions] = useState<ContentSuggestions | null>(null);

  const generateAISuggestions = async () => {
    if (!transcript) {
      toast.error('Please provide a transcript');
      return;
    }

    setLoading(true);
    try {
      const suggestions = await ai.generateContentSuggestions(transcript);
      setAiSuggestions(suggestions);
      setShowAIModal(true);
    } catch (error) {
      console.error('Error generating suggestions:', error);
      toast.error('Failed to generate AI suggestions');
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptSuggestions = async (selected: {
    titles: string[];
    thumbnailTexts: string[];
    descriptions: string[];
  }) => {
    try {
      await ai.saveContentSuggestions(videoId, videoType, {
        titles: selected.titles,
        thumbnailTexts: selected.thumbnailTexts,
        descriptions: selected.descriptions
      });
      
      if (onSuggestionsGenerated) {
        onSuggestionsGenerated();
      }
      
      toast.success('Content suggestions saved successfully');
    } catch (error) {
      console.error('Error saving suggestions:', error);
      toast.error('Failed to save suggestions');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold text-gray-900">Content Options</h3>
        <div className="space-x-4">
          <button
            onClick={generateAISuggestions}
            disabled={loading || !transcript}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
          >
            {loading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Generating...</span>
              </>
            ) : (
              <>
                <Sparkles className="h-4 w-4" />
                <span>Generate AI Suggestions</span>
              </>
            )}
          </button>
        </div>
      </div>

      {aiSuggestions && (
        <AISuggestionsModal
          isOpen={showAIModal}
          onClose={() => setShowAIModal(false)}
          onAccept={handleAcceptSuggestions}
          suggestions={aiSuggestions}
          isLoading={loading}
          onRegenerateRequest={generateAISuggestions}
        />
      )}
    </div>
  );
}