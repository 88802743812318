import React, { useState } from 'react';
import { Video } from 'lucide-react';
import { AuthForm } from '../components/AuthForm';

const Login = () => {
  const [mode, setMode] = useState<'login' | 'reset'>('login');

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <div className="flex justify-center">
            <Video className="h-12 w-12 text-indigo-600" />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Video Production Workflow
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            {mode === 'login' 
              ? 'Sign in to your account'
              : 'Reset your password'}
          </p>
        </div>

        <AuthForm mode={mode} onModeChange={setMode} />
      </div>
    </div>
  );
};

export default Login;