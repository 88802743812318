import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

export type Comment = {
  id: string;
  content: string;
  created_at: string;
  user: {
    full_name: string;
  };
};

export function useVideoComments(videoId: string) {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const { data, error: fetchError } = await supabase
          .from('video_comments')
          .select(`
            id,
            content,
            created_at,
            user:user_id(full_name)
          `)
          .eq('video_id', videoId)
          .order('created_at', { ascending: false });

        if (fetchError) throw fetchError;
        setComments(data || []);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to fetch comments'));
      } finally {
        setLoading(false);
      }
    };

    fetchComments();

    // Set up real-time subscription
    const subscription = supabase
      .channel('video_comments_changes')
      .on('postgres_changes', 
        { event: '*', schema: 'public', table: 'video_comments', filter: `video_id=eq.${videoId}` },
        () => fetchComments()
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [videoId]);

  const addComment = async (content: string) => {
    try {
      const { error } = await supabase
        .from('video_comments')
        .insert({
          video_id: videoId,
          user_id: supabase.auth.getUser().then(({ data }) => data.user?.id),
          content
        });

      if (error) throw error;
    } catch (err) {
      throw err instanceof Error ? err : new Error('Failed to add comment');
    }
  };

  const updateComment = async (commentId: string, content: string) => {
    try {
      const { error } = await supabase
        .from('video_comments')
        .update({ content })
        .eq('id', commentId)
        .eq('user_id', supabase.auth.getUser().then(({ data }) => data.user?.id));

      if (error) throw error;
    } catch (err) {
      throw err instanceof Error ? err : new Error('Failed to update comment');
    }
  };

  return {
    comments,
    loading,
    error,
    addComment,
    updateComment
  };
}