import { supabase } from './supabase';
import { getEnvVar } from './config';
import toast from 'react-hot-toast';

class MetaService {
  private graphUrl = 'https://graph.facebook.com/v18.0';
  private authUrl = 'https://www.facebook.com/v18.0/dialog/oauth';

  private getRedirectUri(): string {
    return 'https://aberdeenapps.com/auth/facebook/callback';
  }

  async initiateOAuth() {
    // Debug all potentially relevant environment variables
    console.log('Environment Variables Debug:', {
      FACEBOOK_APP_ID: import.meta.env.VITE_FACEBOOK_APP_ID,
      NODE_ENV: import.meta.env.MODE,
      allEnvVars: Object.keys(import.meta.env)
    });

    try {
      const appId = import.meta.env.VITE_FACEBOOK_APP_ID;
      console.log('Resolved App ID:', appId);
      
      if (!appId) {
        console.error('Facebook App ID is missing. Environment check failed.');
        throw new Error('Facebook App ID not configured');
      }

      // Get current session
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('Not authenticated');

      // Create state parameter with session info
      const state = Math.random().toString(36).substring(7);
      localStorage.setItem('oauth_state', state);

      const redirectUri = this.getRedirectUri();
      
      // Validate redirect URI
      try {
        new URL(redirectUri);
      } catch (e) {
        throw new Error(`Invalid redirect URI: ${redirectUri}`);
      }

      // Facebook Login for Business specific parameters
      const params = new URLSearchParams({
        client_id: appId,
        redirect_uri: redirectUri,
        state,
        scope: [
          'instagram_basic',
          'instagram_content_publish',
          'pages_show_list',
          'pages_read_engagement',
          'pages_manage_posts',
          'pages_manage_metadata'
        ].join(',')
      });

      const authUrl = `${this.authUrl}?${params.toString()}`;
      
      // Validate final URL
      try {
        new URL(authUrl);
      } catch (e) {
        throw new Error(`Invalid auth URL generated: ${authUrl}`);
      }

      console.log('Full OAuth URL:', authUrl);
      console.log('URL Parameters:', {
        client_id: appId,
        redirect_uri: redirectUri,
        state,
        scope: params.get('scope')
      });

      return authUrl;
    } catch (error) {
      console.error('Detailed OAuth Error:', {
        error,
        message: error instanceof Error ? error.message : 'Unknown error',
        stack: error instanceof Error ? error.stack : undefined,
        envVarsAvailable: !!import.meta.env
      });
      throw error;
    }
  }

  async handleCallback(code: string, state: string) {
    try {
      // Verify state
      const savedState = localStorage.getItem('facebook_oauth_state');
      if (!savedState || state !== savedState) {
        throw new Error('Invalid state parameter');
      }

      const redirectUri = this.getRedirectUri();
      toast.success(`Callback redirect URI: ${redirectUri}`);

      // Exchange code for access token using Supabase Edge Function
      const { data: exchangeData, error: exchangeError } = await supabase.functions.invoke(
        'facebook-token-exchange',
        {
          body: {
            code,
            redirect_uri: redirectUri
          }
        }
      );

      if (exchangeError) {
        console.error('Token exchange failed:', exchangeError);
        throw exchangeError;
      }

      const { access_token, user_id, expires_in } = exchangeData;

      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('No authenticated user found');

      // First, try to delete any existing tokens for this provider and user
      await supabase
        .from('user_oauth_tokens')
        .delete()
        .match({ 
          user_id: user.id, 
          provider: 'facebook',
          provider_user_id: user_id 
        });

      // Then insert the new token
      const { error: insertError } = await supabase
        .from('user_oauth_tokens')
        .insert({
          user_id: user.id,
          provider: 'facebook',
          access_token,
          provider_user_id: user_id,
          expires_at: new Date(Date.now() + expires_in * 1000).toISOString()
        });

      if (insertError) throw insertError;

      // Clear the state from localStorage
      localStorage.removeItem('facebook_oauth_state');

      return { access_token, user_id };
    } catch (error) {
      console.error('Error in handleCallback:', error);
      throw error;
    }
  }

  async getLatestTokens(): Promise<string> {
    try {
      const { data: tokenData, error } = await supabase
        .from('user_oauth_tokens')
        .select('*')
        .eq('provider', 'facebook')
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      if (error) throw error;
      if (!tokenData) throw new Error('No Facebook tokens found');

      return tokenData.access_token;
    } catch (error) {
      console.error('Error getting tokens:', error);
      throw error;
    }
  }

  async getInstagramBusinessAccount() {
    try {
      const token = await this.getLatestTokens();
      
      // Get Facebook pages
      console.log('Fetching Facebook pages with token:', token);
      const pagesResponse = await fetch(
        `${this.graphUrl}/me/accounts?access_token=${token}`
      );

      if (!pagesResponse.ok) {
        const error = await pagesResponse.json();
        throw new Error(`Failed to fetch Facebook pages: ${JSON.stringify(error, null, 2)}`);
      }

      const pagesData = await pagesResponse.json();
      if (!pagesData.data || pagesData.data.length === 0) {
        throw new Error('No Facebook pages found');
      }

      // Get Instagram business account for the first page
      const page = pagesData.data[0];
      const igResponse = await fetch(
        `${this.graphUrl}/${page.id}?fields=instagram_business_account&access_token=${token}`
      );

      if (!igResponse.ok) {
        const error = await igResponse.json();
        throw new Error(`Failed to fetch Instagram account: ${JSON.stringify(error, null, 2)}`);
      }

      const igData = await igResponse.json();
      if (!igData.instagram_business_account?.id) {
        throw new Error('No Instagram business account found');
      }

      return igData.instagram_business_account.id;
    } catch (error) {
      console.error('Error getting Instagram business account:', error);
      throw error;
    }
  }

  async publishMedia(imageUrl: string, caption: string = '') {
    try {
      const token = await this.getLatestTokens();
      const instagramAccountId = await this.getInstagramBusinessAccount();
      
      console.log('Publishing media to Instagram...', {
        accountId: instagramAccountId,
        imageUrl
      });

      // Create container
      const containerResponse = await fetch(
        `${this.graphUrl}/${instagramAccountId}/media`,
        {
          method: 'POST',
          body: new URLSearchParams({
            access_token: token,
            image_url: imageUrl,
            caption
          })
        }
      );

      if (!containerResponse.ok) {
        const error = await containerResponse.json();
        throw new Error(`Failed to create media container: ${JSON.stringify(error, null, 3)}`);
      }

      const { id: containerId } = await containerResponse.json();

      // Publish the container
      const publishResponse = await fetch(
        `${this.graphUrl}/${instagramAccountId}/media_publish`,
        {
          method: 'POST',
          body: new URLSearchParams({
            access_token: token,
            creation_id: containerId
          })
        }
      );

      if (!publishResponse.ok) {
        const error = await publishResponse.json();
        throw new Error(`Failed to publish media: ${JSON.stringify(error, null, 3)}`);
      }

      return await publishResponse.json();
    } catch (error) {
      console.error('Error publishing to Instagram:', error);
      throw error;
    }
  }

  async getMediaStatus(mediaId: string) {
    try {
      const { instagramAccountId, pageAccessToken } = await this.getInstagramBusinessAccount();

      const url = new URL(`${this.graphUrl}/${mediaId}`);
      url.searchParams.append('access_token', pageAccessToken);
      url.searchParams.append('fields', 'status_code,status');

      const response = await fetch(url.toString());
      if (!response.ok) {
        const error = await response.text();
        throw new Error(`Failed to get media status: ${error}`);
      }

      return response.json();
    } catch (error) {
      console.error('Error getting media status:', error);
      throw error;
    }
  }

  async publishVideo(videoUrl: string, caption: string = '') {
    try {
      const token = await this.getLatestTokens();
      const instagramAccountId = await this.getInstagramBusinessAccount();
      
      console.log('Publishing video to Instagram...', {
        accountId: instagramAccountId,
        videoUrl
      });

      // Create container for video
      const containerResponse = await fetch(
        `${this.graphUrl}/${instagramAccountId}/media`,
        {
          method: 'POST',
          body: new URLSearchParams({
            access_token: token,
            media_type: 'REELS',
            video_url: videoUrl,
            caption
          })
        }
      );

      if (!containerResponse.ok) {
        const error = await containerResponse.json();
        throw new Error(`Failed to create video container: ${JSON.stringify(error, null, 3)}`);
      }

      const { id: containerId } = await containerResponse.json();

      // Wait for video processing
      let status = 'IN_PROGRESS';
      while (status === 'IN_PROGRESS' || status === 'PENDING') {
        const statusResponse = await fetch(
          `${this.graphUrl}/${containerId}?fields=status_code&access_token=${token}`
        );
        
        if (!statusResponse.ok) {
          throw new Error('Failed to check video status');
        }

        const { status_code } = await statusResponse.json();
        status = status_code;

        if (status === 'ERROR') {
          throw new Error('Video processing failed');
        }

        if (status === 'IN_PROGRESS' || status === 'PENDING') {
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds before checking again
        }
      }

      // Publish the container
      const publishResponse = await fetch(
        `${this.graphUrl}/${instagramAccountId}/media_publish`,
        {
          method: 'POST',
          body: new URLSearchParams({
            access_token: token,
            creation_id: containerId
          })
        }
      );

      if (!publishResponse.ok) {
        const error = await publishResponse.json();
        throw new Error(`Failed to publish video: ${JSON.stringify(error, null, 3)}`);
      }

      return await publishResponse.json();
    } catch (error) {
      console.error('Error publishing video to Instagram:', error);
      throw error;
    }
  }

  async publishToFacebook(mediaUrl: string, caption: string = '', isVideo: boolean = false) {
    try {
      const token = await this.getLatestTokens();
      console.log('Fetching Facebook pages for publishing...');
      
      const pagesResponse = await fetch(
        `${this.graphUrl}/me/accounts?access_token=${token}`
      );

      if (!pagesResponse.ok) {
        const error = await pagesResponse.json();
        throw new Error(`Failed to fetch Facebook pages: ${JSON.stringify(error, null, 3)}`);
      }

      const pagesData = await pagesResponse.json();
      if (!pagesData.data || pagesData.data.length === 0) {
        throw new Error('No Facebook pages found');
      }

      const page = pagesData.data[0];
      const pageToken = page.access_token;
      
      console.log('Publishing to Facebook page:', page.id);

      // Publish to Facebook page
      const publishResponse = await fetch(
        `${this.graphUrl}/${page.id}/${isVideo ? 'videos' : 'photos'}`,
        {
          method: 'POST',
          body: new URLSearchParams({
            access_token: pageToken,
            [isVideo ? 'file_url' : 'url']: mediaUrl,
            caption,
            published: 'true'
          })
        }
      );

      if (!publishResponse.ok) {
        const error = await publishResponse.json();
        throw new Error(`Failed to publish to Facebook: ${JSON.stringify(error, null, 3)}`);
      }

      return await publishResponse.json();
    } catch (error) {
      console.error('Error publishing to Facebook:', error);
      throw error;
    }
  }
}

// Create and export singleton instance
export const meta = new MetaService();