import React, { useState } from 'react';
import { Link as LinkIcon, Youtube, ExternalLink, HelpCircle } from 'lucide-react';
import toast from 'react-hot-toast';

type Props = {
  frameioLink?: string;
  youtubeLink?: string;
  onUpdate: (updates: { frameioLink?: string; youtubeLink?: string }) => Promise<void>;
  showYoutubeField?: boolean;
};

export function VideoLinkManager({ frameioLink, youtubeLink, onUpdate, showYoutubeField = false }: Props) {
  const [links, setLinks] = useState({
    frameio: frameioLink || '',
    youtube: youtubeLink || ''
  });
  const [updating, setUpdating] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const validateFrameioLink = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      // Frame.io V4 URLs follow this pattern: https://app.frame.io/projects/{projectId}
      return parsedUrl.hostname === 'app.frame.io' && parsedUrl.pathname.startsWith('/projects/');
    } catch {
      return false;
    }
  };

  const validateYoutubeLink = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname.includes('youtube.com') || parsedUrl.hostname.includes('youtu.be');
    } catch {
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate Frame.io link
    if (links.frameio && !validateFrameioLink(links.frameio)) {
      toast.error('Please enter a valid Frame.io project link (e.g., https://app.frame.io/projects/...)');
      return;
    }

    // Validate YouTube link if present
    if (links.youtube && !validateYoutubeLink(links.youtube)) {
      toast.error('Please enter a valid YouTube link');
      return;
    }

    setUpdating(true);
    try {
      await onUpdate({
        frameioLink: links.frameio,
        youtubeLink: links.youtube
      });
      toast.success('Links updated successfully');
    } catch (error) {
      console.error('Error updating links:', error);
      toast.error('Failed to update links');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">Video Links</h2>
        <button
          type="button"
          onClick={() => setShowHelp(!showHelp)}
          className="text-gray-400 hover:text-gray-500"
        >
          <HelpCircle className="h-5 w-5" />
        </button>
      </div>

      {showHelp && (
        <div className="mb-6 p-4 bg-blue-50 rounded-lg">
          <h3 className="font-medium text-blue-900 mb-2">How to get the Frame.io link:</h3>
          <ol className="list-decimal list-inside space-y-2 text-sm text-blue-800">
            <li>Upload your video to Frame.io</li>
            <li>Open the project containing your video</li>
            <li>Copy the project URL from your browser's address bar</li>
            <li>The URL should look like: https://app.frame.io/projects/your-project-id</li>
          </ol>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Frame.io Project Link
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
              <LinkIcon className="h-4 w-4" />
            </span>
            <input
              type="url"
              value={links.frameio}
              onChange={(e) => setLinks(prev => ({ ...prev, frameio: e.target.value }))}
              className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="https://app.frame.io/projects/..."
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Add the Frame.io project link for collaborative editing
          </p>
        </div>

        {showYoutubeField && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              YouTube Link
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                <Youtube className="h-4 w-4" />
              </span>
              <input
                type="url"
                value={links.youtube}
                onChange={(e) => setLinks(prev => ({ ...prev, youtube: e.target.value }))}
                className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="https://youtube.com/watch?v=..."
              />
            </div>
            <p className="mt-1 text-sm text-gray-500">
              Add the YouTube link once the video is published
            </p>
          </div>
        )}

        {(links.frameio || links.youtube) && (
          <div className="mt-4 space-y-2">
            {links.frameio && (
              <a
                href={links.frameio}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-1 text-indigo-600 hover:text-indigo-700"
              >
                <ExternalLink className="h-4 w-4" />
                <span>Open in Frame.io</span>
              </a>
            )}
            {links.youtube && (
              <a
                href={links.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-1 text-indigo-600 hover:text-indigo-700"
              >
                <ExternalLink className="h-4 w-4" />
                <span>View on YouTube</span>
              </a>
            )}
          </div>
        )}

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={updating}
            className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {updating ? 'Saving...' : 'Save Links'}
          </button>
        </div>
      </form>
    </div>
  );
}