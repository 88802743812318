import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import SimpleSignup from '../components/SimpleSignup';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

type UserData = {
  id: string;
  email: string;
  role: string;
  created_at: string;
  username: string;
};

export default function AdminDashboard() {
  const { user } = useAuth();
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingUserId, setEditingUserId] = useState<string | null>(null);
  const [pendingRoleChanges, setPendingRoleChanges] = useState<{[key: string]: string}>({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (user) {
      // Check admin status from public.users table
      supabase
        .from('users')
        .select('role')
        .eq('id', user.id)
        .single()
        .then(({ data }) => {
          setIsAdmin(data?.role === 'admin');
        });
    }
  }, [user]);

  const fetchUsers = async () => {
    try {
      console.log('Fetching users...');
      
      // Add noCache option to ensure fresh data
      const { data: users, error } = await supabase
        .from('users')
        .select('*')
        .order('created_at', { ascending: false })
        .throwOnError();  // Add this to throw on error

      console.log('Users query response:', { users, error });

      if (error) {
        console.error('Users fetch error:', error);
        throw error;
      }

      if (!users || users.length === 0) {
        console.log('No users found in the database');
      }

      const processedUsers = (users || []).map(user => ({
        id: user.id,
        email: user.email,
        role: user.role || 'unassigned',
        created_at: user.created_at,
        username: user.username || user.email?.split('@')[0] || ''
      }));

      console.log('Processed users:', processedUsers);
      setUsers(processedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast.error('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const updateUserRole = async (userId: string, newRole: string) => {
    try {
      console.log('Updating user role:', { userId, newRole });

      const { error } = await supabase
        .from('users')
        .update({ 
          role: newRole as any,  // Cast to any to bypass TypeScript checking
          updated_at: new Date().toISOString()
        })
        .eq('id', userId);

      if (error) {
        console.error('Database error:', error);
        throw error;
      }

      // Refresh the users list to get the updated data
      fetchUsers();
      
      toast.success('User role updated successfully');
      setEditingUserId(null);
    } catch (error) {
      console.error('Error updating user role:', error);
      toast.error('Failed to update user role');
      setEditingUserId(null);
    }
  };

  const handleRoleChange = (userId: string, newRole: string) => {
    setPendingRoleChanges(prev => ({
      ...prev,
      [userId]: newRole
    }));
  };

  const commitRoleChanges = async () => {
    try {
      setLoading(true);
      console.log('Starting role updates with changes:', pendingRoleChanges);

      for (const [userId, newRole] of Object.entries(pendingRoleChanges)) {
        console.log(`Attempting to update user ${userId} to role ${newRole}`);
        
        // Use rpc to execute the update with proper casting
        const { data, error } = await supabase.rpc('update_user_role', {
          user_id: userId,
          new_role: newRole
        });

        console.log('Update attempt result:', { data, error });

        if (error) {
          console.error('Error updating user:', error);
          throw error;
        }
      }

      // Fetch fresh data after updates
      await fetchUsers();
      
      setPendingRoleChanges({});
      setEditingUserId(null);
      toast.success('Role changes saved successfully');
    } catch (error) {
      console.error('Error updating roles:', error);
      toast.error('Failed to update roles');
    } finally {
      setLoading(false);
    }
  };

  if (!isAdmin) {
    return <div className="p-4 bg-red-50 text-red-600 rounded">Access denied. Admin privileges required.</div>;
  }

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold">Admin Dashboard</h1>
      
      {/* Create New User */}
      <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Create New User</h2>
        <SimpleSignup onUserCreated={fetchUsers} />
      </div>

      {/* User Management */}
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">User Management</h2>
          {Object.keys(pendingRoleChanges).length > 0 && (
            <div className="space-x-4">
              <button
                onClick={() => {
                  setPendingRoleChanges({});
                  setEditingUserId(null);
                }}
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel Changes
              </button>
              <button
                onClick={commitRoleChanges}
                disabled={loading}
                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 disabled:opacity-50"
              >
                {loading ? 'Saving...' : 'Save Role Changes'}
              </button>
            </div>
          )}
        </div>

        {loading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((userData) => (
                  <tr key={userData.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">{userData.email}</div>
                      <div className="text-sm text-gray-500">{userData.username}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {editingUserId === userData.id ? (
                        <select
                          value={pendingRoleChanges[userData.id] || userData.role}
                          onChange={(e) => handleRoleChange(userData.id, e.target.value)}
                          className="mt-1 block w-full py-2 px-3 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                          <option value="" disabled>Select a role...</option>
                          <option value="admin">Admin</option>
                          <option value="editor">Editor</option>
                          <option value="reviewer">Reviewer</option>
                          <option value="designer">Designer</option>
                          <option value="content_strategist">Content Strategist</option>
                          <option value="social_media_manager">Social Media Manager</option>
                        </select>
                      ) : (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          userData.role === 'admin' 
                            ? 'bg-purple-100 text-purple-800' 
                            : userData.role === 'unassigned'
                              ? 'bg-gray-100 text-gray-800'
                              : 'bg-green-100 text-green-800'
                        }`}>
                          {userData.role === 'unassigned' ? 'No Role' : userData.role}
                        </span>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(userData.created_at).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {editingUserId === userData.id ? (
                        <button
                          onClick={() => setEditingUserId(null)}
                          className="text-gray-600 hover:text-gray-900"
                        >
                          Cancel
                        </button>
                      ) : (
                        <button
                          onClick={() => setEditingUserId(userData.id)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit Role
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
} 