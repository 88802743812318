import React, { useState } from 'react';
import { Loader2, X } from 'lucide-react';
import { ContentSuggestions } from '../lib/ai';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAccept: (selected: {
    titles: string[];
    thumbnailTexts: string[];
    descriptions: string[];
  }) => void;
  suggestions: ContentSuggestions;
  isLoading: boolean;
  onRegenerateRequest: () => void;
};

export function AISuggestionsModal({
  isOpen,
  onClose,
  onAccept,
  suggestions,
  isLoading,
  onRegenerateRequest
}: Props) {
  const [selectedTitles, setSelectedTitles] = useState<string[]>([]);
  const [selectedThumbnailTexts, setSelectedThumbnailTexts] = useState<string[]>([]);
  const [selectedDescriptions, setSelectedDescriptions] = useState<string[]>([]);

  if (!isOpen) return null;

  const handleAccept = () => {
    onAccept({
      titles: selectedTitles,
      thumbnailTexts: selectedThumbnailTexts,
      descriptions: selectedDescriptions
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">AI Generated Content Suggestions</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        {isLoading ? (
          <div className="flex flex-col items-center justify-center py-12">
            <Loader2 className="h-8 w-8 animate-spin text-indigo-600" />
            <p className="mt-2 text-gray-600">Generating new suggestions...</p>
          </div>
        ) : (
          <div className="space-y-6">
            <div>
              <h3 className="font-medium mb-2">Titles</h3>
              <div className="space-y-2">
                {suggestions.titles.map((title, index) => (
                  <label key={index} className="flex items-start space-x-2">
                    <input
                      type="checkbox"
                      checked={selectedTitles.includes(title)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTitles([...selectedTitles, title]);
                        } else {
                          setSelectedTitles(selectedTitles.filter(t => t !== title));
                        }
                      }}
                      className="mt-1"
                    />
                    <span>{title}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-2">Thumbnail Text Options</h3>
              <div className="space-y-2">
                {suggestions.thumbnailTexts.map((text, index) => (
                  <label key={index} className="flex items-start space-x-2">
                    <input
                      type="checkbox"
                      checked={selectedThumbnailTexts.includes(text)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedThumbnailTexts([...selectedThumbnailTexts, text]);
                        } else {
                          setSelectedThumbnailTexts(selectedThumbnailTexts.filter(t => t !== text));
                        }
                      }}
                      className="mt-1"
                    />
                    <span>{text}</span>
                  </label>
                ))}
              </div>
            </div>

            <div>
              <h3 className="font-medium mb-2">Descriptions</h3>
              <div className="space-y-2">
                {suggestions.descriptions.map((desc, index) => (
                  <label key={index} className="flex items-start space-x-2">
                    <input
                      type="checkbox"
                      checked={selectedDescriptions.includes(desc)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDescriptions([...selectedDescriptions, desc]);
                        } else {
                          setSelectedDescriptions(selectedDescriptions.filter(d => d !== desc));
                        }
                      }}
                      className="mt-1"
                    />
                    <span className="text-sm">{desc}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="flex justify-end space-x-4 mt-6">
              <button
                onClick={onRegenerateRequest}
                className="px-4 py-2 text-indigo-600 hover:bg-indigo-50 rounded-md"
              >
                Generate More Options
              </button>
              <button
                onClick={handleAccept}
                disabled={!selectedTitles.length || !selectedThumbnailTexts.length || !selectedDescriptions.length}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
              >
                Accept Selected Options
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
} 