import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Clock, Users, MessageSquare, CheckCircle, Trash, Youtube, Send, Edit, X } from 'lucide-react';
import { CommentSection } from '../components/CommentSection';
import { StatusUpdateModal } from '../components/StatusUpdateModal';
import { VideoLinkManager } from '../components/VideoLinkManager';
import { TranscriptSection } from '../components/TranscriptSection';
import { ContentApprovalSection } from '../components/ContentApprovalSection';
import { ReviewModal } from '../components/ReviewModal';
import { useVideoDetail } from '../hooks/useVideoDetail';
import { formatDistanceToNow } from 'date-fns';
import { useRole } from '../contexts/RoleContext';
import toast from 'react-hot-toast';
import { supabase } from '../lib/supabase';

const VideoDetail = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();
  const { video, loading, error, isDeleted, updateStatus, updateLinks, deleteVideo, refreshVideo } = useVideoDetail(
    id!,
    type as 'long-form' | 'short-form'
  );
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [hasUnapprovedContent, setHasUnapprovedContent] = useState(false);
  const { hasPermission } = useRole();
  const contentApprovalRef = useRef<any>(null);

  useEffect(() => {
    if (isDeleted) {
      navigate(`/${type}`);
    }
  }, [isDeleted, navigate, type]);

  // Check for unapproved content
  useEffect(() => {
    const checkUnapprovedContent = async () => {
      if (!id) return;

      try {
        // Check titles
        const { data: titles, error: titleError } = await supabase
          .from('video_titles')
          .select('is_approved')
          .eq('video_id', id)
          .eq('video_type', type)
          .eq('is_approved', false);

        if (titleError) throw titleError;

        // Check descriptions
        const { data: descriptions, error: descError } = await supabase
          .from('video_descriptions')
          .select('is_approved')
          .eq('video_id', id)
          .eq('video_type', type)
          .eq('is_approved', false);

        if (descError) throw descError;

        // Check thumbnails
        const { data: thumbnails, error: thumbError } = await supabase
          .from('video_thumbnails')
          .select('is_approved')
          .eq('video_id', id)
          .eq('video_type', type)
          .eq('is_approved', false);

        if (thumbError) throw thumbError;

        // Set hasUnapprovedContent if any unapproved content exists
        setHasUnapprovedContent(
          (titles?.length || 0) > 0 || 
          (descriptions?.length || 0) > 0 || 
          (thumbnails?.length || 0) > 0
        );
      } catch (error) {
        console.error('Error checking for unapproved content:', error);
      }
    };

    checkUnapprovedContent();
  }, [id, type]);

  const handleStatusUpdate = async (newStatus: string) => {
    try {
      await updateStatus(newStatus);
      toast.success('Status updated successfully');
      setShowStatusModal(false);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteVideo();
      toast.success('Video deleted successfully');
      navigate(`/${type}`);
    } catch (error) {
      console.error('Error deleting video:', error);
      toast.error('Failed to delete video');
    }
  };

  const handleSubmitForReview = async () => {
    try {
      // Update content review status to pending_review
      const tableName = type === 'long-form' ? 'long_form_videos' : 'short_form_videos';
      const { error } = await supabase
        .from(tableName)
        .update({ content_review_status: 'pending_review' })
        .eq('id', id);

      if (error) throw error;

      await updateStatus('ready_for_review');
      toast.success('Content submitted for review');
    } catch (error) {
      console.error('Error submitting for review:', error);
      toast.error('Failed to submit for review');
    }
  };

  const handleContentApproved = async () => {
    try {
      await refreshVideo();
      // Explicitly refresh the content options
      if (contentApprovalRef.current) {
        await contentApprovalRef.current.loadContentOptions();
      }
      toast.success('Content approved successfully');
    } catch (error) {
      console.error('Error refreshing video:', error);
      toast.error('Failed to refresh video details');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error || !video) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-red-50 text-red-600 p-4 rounded-lg">
          Failed to load video details. Please try again later.
        </div>
      </div>
    );
  }

  const getStatusColor = (status: string) => {
    const colors = {
      draft: 'bg-gray-100 text-gray-800',
      editing: 'bg-blue-100 text-blue-800',
      ready_for_review: 'bg-yellow-100 text-yellow-800',
      revisions_needed: 'bg-red-100 text-red-800',
      edit_approved: 'bg-green-100 text-green-800',
      approved: 'bg-green-100 text-green-800',
      needs_title_thumbnail_description: 'bg-purple-100 text-purple-800',
      ready_for_posting: 'bg-indigo-100 text-indigo-800',
      scheduled: 'bg-orange-100 text-orange-800',
      published: 'bg-emerald-100 text-emerald-800'
    };
    return colors[status as keyof typeof colors] || 'bg-gray-100 text-gray-800';
  };

  const canReview = video.status === 'ready_for_review' && 
                   hasPermission('review_video') && 
                   video.content_review_status === 'pending_review';

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{video.title}</h1>
          <p className="text-gray-500 mt-1">ID: {id}</p>
        </div>
        <div className="flex items-center space-x-2">
          {hasPermission('review_video') && (
            <button
              onClick={() => setShowReviewModal(true)}
              disabled={!canReview}
              className={`px-4 py-2 rounded-md flex items-center space-x-2 ${
                canReview
                  ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                  : 'bg-gray-100 text-gray-400 cursor-not-allowed'
              }`}
              title={!canReview ? 'No content to review' : undefined}
            >
              <Edit className="h-4 w-4" />
              <span>Review & Approve</span>
            </button>
          )}
          {hasPermission('manage_users') && (
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="p-2 text-red-600 hover:text-red-700 rounded-md hover:bg-red-50"
            >
              <Trash className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>

      {/* Status Bar */}
      <div className="bg-white shadow rounded-lg p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium text-gray-500">Current Status:</span>
            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(video.status)}`}>
              {video.status.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </span>
          </div>
          <button
            onClick={() => setShowStatusModal(true)}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 flex items-center space-x-2"
          >
            <CheckCircle className="h-4 w-4" />
            <span>Update Status</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          {/* Video Preview Section */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Video Preview</h2>
            <div className="aspect-video bg-gray-100 rounded-lg"></div>
          </div>

          {/* Transcript Section */}
          <TranscriptSection
            videoId={video.id}
            videoType={type as 'long-form' | 'short-form'}
            initialTranscript={video.transcript}
          />

          {/* Content Approval Section */}
          <ContentApprovalSection
            ref={contentApprovalRef}
            videoId={video.id}
            videoType={type as 'long-form' | 'short-form'}
            transcript={video.transcript || ''}
            onContentApproved={handleContentApproved}
            onSubmitForReview={handleSubmitForReview}
          />

          {/* Video Links Section */}
          <VideoLinkManager
            frameioLink={video.frameio_link}
            youtubeLink={video.youtube_link}
            onUpdate={updateLinks}
            showYoutubeField={type === 'long-form'}
          />

          {/* Comments Section */}
          <CommentSection videoId={video.id} />
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Video Details */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Video Details</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-500">Created</label>
                <p className="mt-1 text-sm text-gray-900">
                  {formatDistanceToNow(new Date(video.created_at), { addSuffix: true })}
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-500">Last Updated</label>
                <p className="mt-1 text-sm text-gray-900">
                  {formatDistanceToNow(new Date(video.updated_at), { addSuffix: true })}
                </p>
              </div>
              {video.assigned_editor && (
                <div>
                  <label className="block text-sm font-medium text-gray-500">Editor</label>
                  <p className="mt-1 text-sm text-gray-900">{video.editor?.full_name}</p>
                </div>
              )}
              {video.assigned_reviewer && (
                <div>
                  <label className="block text-sm font-medium text-gray-500">Reviewer</label>
                  <p className="mt-1 text-sm text-gray-900">{video.reviewer?.full_name}</p>
                </div>
              )}
              {video.scheduled_publish_date && (
                <div>
                  <label className="block text-sm font-medium text-gray-500">Scheduled For</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {new Date(video.scheduled_publish_date).toLocaleDateString()}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Delete Video</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this video? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Status Update Modal */}
      {showStatusModal && (
        <StatusUpdateModal
          currentStatus={video.status}
          onClose={() => setShowStatusModal(false)}
          onUpdate={handleStatusUpdate}
        />
      )}

      {/* Review Modal */}
      {showReviewModal && (
        <ReviewModal
          videoId={video.id}
          videoType={type as 'long-form' | 'short-form'}
          onClose={() => setShowReviewModal(false)}
          onApprove={handleContentApproved}
        />
      )}
    </div>
  );
};

export default VideoDetail;