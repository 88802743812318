import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './contexts/AuthContext';
import { RoleProvider } from './contexts/RoleContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import Navigation from './components/Navigation';
import Login from './pages/Login';
import PrivacyPolicy from './pages/PrivacyPolicy';
import YouTubeCallback from './pages/YouTubeCallback';
import InstagramCallback from './pages/InstagramCallback';
import VideoDetail from './pages/VideoDetail';
import { FacebookCallback } from './components/FacebookCallback';
import AdminDashboard from './pages/AdminDashboard';

// Lazy load other components
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const LongFormVideos = React.lazy(() => import('./pages/LongFormVideos'));
const ShortFormVideos = React.lazy(() => import('./pages/ShortFormVideos'));
const Podcasts = React.lazy(() => import('./pages/Podcasts'));
const Tasks = React.lazy(() => import('./pages/Tasks'));
const Performance = React.lazy(() => import('./pages/Performance'));
const NewVideo = React.lazy(() => import('./pages/NewVideo'));
const PodcastDetail = React.lazy(() => import('./pages/PodcastDetail'));
const Account = React.lazy(() => import('./pages/Account'));
const APITests = React.lazy(() => import('./pages/APITests'));

// Loading component
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
  </div>
);

function App() {
  return (
    <Router>
      <AuthProvider>
        <RoleProvider>
          <NotificationProvider>
            <div className="min-h-screen bg-gray-50">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/auth/youtube/callback" element={<YouTubeCallback />} />
                <Route path="/auth/instagram/callback" element={<InstagramCallback />} />
                <Route path="/auth/facebook/callback" element={<FacebookCallback />} />
                
                {/* Protected Routes */}
                <Route
                  path="/*"
                  element={
                    <ProtectedRoute>
                      <Navigation />
                      <div className="container mx-auto px-4 py-8">
                        <Suspense fallback={<LoadingSpinner />}>
                          <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/long-form" element={<LongFormVideos />} />
                            <Route path="/short-form" element={<ShortFormVideos />} />
                            <Route path="/podcasts" element={<Podcasts />} />
                            <Route path="/tasks" element={<Tasks />} />
                            <Route path="/performance" element={<Performance />} />
                            <Route path="/video/:type/new" element={<NewVideo />} />
                            <Route path="/video/:type/:id" element={<VideoDetail />} />
                            <Route path="/podcast/:id" element={<PodcastDetail />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/api-tests" element={<APITests />} />
                            <Route path="/admin" element={<AdminDashboard />} />
                          </Routes>
                        </Suspense>
                      </div>
                    </ProtectedRoute>
                  }
                />
              </Routes>
              <Toaster position="top-right" />
            </div>
          </NotificationProvider>
        </RoleProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;