import React, { useState, useRef, useEffect } from 'react';
import { Save, Loader2, Upload, FileText, Edit } from 'lucide-react';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

type Props = {
  videoId: string;
  videoType: 'long-form' | 'short-form';
  initialTranscript?: string | null;
  onTranscriptSave?: () => void;
};

export function TranscriptSection({ videoId, videoType, initialTranscript, onTranscriptSave }: Props) {
  const [transcript, setTranscript] = useState(initialTranscript || '');
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Update local state when initialTranscript changes
  useEffect(() => {
    if (initialTranscript !== undefined) {
      setTranscript(initialTranscript || '');
      setIsEditing(false);
      // Set a default filename if there's a transcript but no filename
      if (initialTranscript && !fileName) {
        setFileName('transcript.txt');
      }
    }
  }, [initialTranscript]);

  const handleSave = async () => {
    if (!transcript.trim()) {
      toast.error('Please enter a transcript');
      return;
    }

    setSaving(true);
    try {
      const tableName = videoType === 'long-form' ? 'long_form_videos' : 'short_form_videos';
      
      // First check if the video exists
      const { data: existingVideo, error: checkError } = await supabase
        .from(tableName)
        .select('id')
        .eq('id', videoId)
        .single();

      if (checkError) {
        throw new Error('Video not found');
      }

      // Update the transcript
      const { error: updateError } = await supabase
        .from(tableName)
        .update({ transcript: transcript.trim() })
        .eq('id', videoId);

      if (updateError) throw updateError;

      toast.success('Transcript saved successfully');
      setIsEditing(false);
      
      // Set default filename if none exists
      if (!fileName) {
        setFileName('transcript.txt');
      }
      
      if (onTranscriptSave) {
        onTranscriptSave();
      }
    } catch (error) {
      console.error('Error saving transcript:', error);
      toast.error('Failed to save transcript. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Only accept .txt files
    if (file.type !== 'text/plain') {
      toast.error('Please upload a .txt file');
      return;
    }

    setUploading(true);
    setFileName(file.name);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target?.result as string;
        setTranscript(text);
        setIsEditing(true);
        setUploading(false);
        toast.success('Transcript loaded successfully');
      };
      reader.onerror = () => {
        throw new Error('Failed to read file');
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error uploading transcript:', error);
      toast.error('Failed to upload transcript');
      setUploading(false);
    }
  };

  const handleCancel = () => {
    setTranscript(initialTranscript || '');
    setIsEditing(false);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-gray-900">Video Transcript</h2>
        <div className="flex items-center space-x-2">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".txt"
            className="hidden"
          />
          {transcript && !isEditing ? (
            <button
              onClick={() => setIsEditing(true)}
              className="flex items-center space-x-2 px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              <Edit className="h-4 w-4" />
              <span>Edit</span>
            </button>
          ) : isEditing ? (
            <>
              <button
                onClick={handleCancel}
                className="flex items-center space-x-2 px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200"
              >
                <span>Cancel</span>
              </button>
              <button
                onClick={handleSave}
                disabled={saving}
                className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
              >
                {saving ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span>Saving...</span>
                  </>
                ) : (
                  <>
                    <Save className="h-4 w-4" />
                    <span>Save</span>
                  </>
                )}
              </button>
            </>
          ) : (
            <button
              onClick={() => fileInputRef.current?.click()}
              disabled={uploading}
              className="flex items-center space-x-2 px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 disabled:opacity-50"
            >
              {uploading ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Uploading...</span>
                </>
              ) : (
                <>
                  <Upload className="h-4 w-4" />
                  <span>Upload</span>
                </>
              )}
            </button>
          )}
        </div>
      </div>

      {transcript && !isEditing ? (
        <div className="flex items-center space-x-2 p-4 bg-gray-50 rounded-lg">
          <FileText className="h-5 w-5 text-gray-500" />
          <span className="text-gray-700">{fileName || 'transcript.txt'}</span>
        </div>
      ) : isEditing ? (
        <textarea
          value={transcript}
          onChange={(e) => setTranscript(e.target.value)}
          className="w-full h-64 p-4 border rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Enter video transcript here..."
        />
      ) : (
        <div className="text-center p-8 bg-gray-50 rounded-lg">
          <FileText className="h-12 w-12 text-gray-400 mx-auto mb-3" />
          <p className="text-gray-500">No transcript uploaded yet</p>
          <p className="text-sm text-gray-400 mt-1">Upload a .txt file to get started</p>
        </div>
      )}
    </div>
  );
}