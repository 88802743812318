import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

export function FacebookCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const processCallback = async () => {
      console.log('Processing Facebook callback...');
      
      // Get the access token from URL
      const params = new URLSearchParams(window.location.search);
      const accessToken = params.get('access_token');
      const error = params.get('error');

      if (error) {
        console.error('Facebook auth error:', error);
        toast.error('Failed to connect to Facebook');
        navigate('/api-tests');
        return;
      }

      if (!accessToken) {
        console.log('No access token received');
        navigate('/api-tests');
        return;
      }

      try {
        // Store the token
        const { error: storeError } = await supabase
          .from('user_oauth_tokens')
          .insert({
            provider: 'facebook',
            access_token: accessToken,
            expires_at: new Date(Date.now() + 2 * 60 * 60 * 1000).toISOString() // 2 hours
          });

        if (storeError) throw storeError;

        toast.success('Connected to Facebook successfully!');
        navigate('/api-tests');
      } catch (error) {
        console.error('Error storing token:', error);
        toast.error('Failed to store Facebook connection');
        navigate('/api-tests');
      }
    };

    processCallback();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h2 className="text-xl font-semibold mb-2">Connecting to Facebook...</h2>
        <p className="text-gray-600">Please wait while we complete the connection.</p>
      </div>
    </div>
  );
} 