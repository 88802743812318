import React, { useState } from 'react';
import { Eye, EyeOff, AlertCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { validatePassword, validateEmail, validateUsername } from '../utils/validation';

type Props = {
  mode: 'login' | 'reset';
  onModeChange: (mode: 'login' | 'reset') => void;
};

export function AuthForm({ mode, onModeChange }: Props) {
  const { signIn, signUp, resetPassword } = useAuth();
  const [formData, setFormData] = useState({
    identifier: '',
    username: '',
    password: '',
    confirmPassword: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (mode === 'reset') {
      if (!validateEmail(formData.identifier)) {
        newErrors.identifier = 'Please enter a valid email address';
      }
    } else {
      if (!formData.identifier) {
        newErrors.identifier = 'This field is required';
      } else if (mode === 'signup' && !validateEmail(formData.identifier)) {
        newErrors.identifier = 'Please enter a valid email address';
      }
      if (!formData.password && mode !== 'reset') {
        newErrors.password = 'Password is required';
      } else if (mode === 'signup') {
        const passwordError = validatePassword(formData.password);
        if (passwordError) {
          newErrors.password = passwordError;
        }
        if (formData.password !== formData.confirmPassword) {
          newErrors.confirmPassword = 'Passwords do not match';
        }
        if (!validateUsername(formData.username)) {
          newErrors.username = 'Username must be 3-20 characters and can only contain letters, numbers, and underscores';
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      if (mode === 'signup') {
        try {
          await signUp(formData.identifier, formData.password, formData.username);
          // After successful signup, switch to login mode
          onModeChange('login');
        } catch (error: any) {
          console.error('Signup error:', error);
          if (error.message.includes('Database error saving new user')) {
            setErrors({ 
              submit: 'Unable to create account. Please try again later or contact support.' 
            });
          } else if (error.message.includes('Username is already taken')) {
            setErrors({ username: 'This username is already taken' });
          } else if (error.message.includes('already exists')) {
            setErrors({ 
              submit: 'An account with this email already exists. Please sign in or use a different email.' 
            });
          } else {
            setErrors({ 
              submit: error.message || 'Failed to create account. Please try again.' 
            });
          }
        }
      } else if (mode === 'login') {
        await signIn(formData.identifier, formData.password);
      } else {
        await resetPassword(formData.identifier);
      }
    } catch (error) {
      console.error('Form submission error:', error);
      if (error instanceof Error) {
        setErrors({ 
          submit: error.message || 'An unexpected error occurred' 
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {errors.submit && (
        <div className="bg-red-50 border border-red-200 rounded-md p-4 flex items-start">
          <AlertCircle className="h-5 w-5 text-red-500 mt-0.5" />
          <p className="ml-3 text-sm text-red-600">{errors.submit}</p>
        </div>
      )}

      <div>
        <label htmlFor="identifier" className="block text-sm font-medium text-gray-700">
          {mode === 'signup' ? 'Email address' : 'Email or Username'}
        </label>
        <div className="mt-1">
          <input
            id="identifier"
            type={mode === 'signup' ? 'email' : 'text'}
            value={formData.identifier}
            onChange={(e) => setFormData(prev => ({ ...prev, identifier: e.target.value }))}
            className={`block w-full rounded-md shadow-sm ${
              errors.identifier 
                ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
            }`}
          />
          {errors.identifier && (
            <p className="mt-1 text-sm text-red-600">{errors.identifier}</p>
          )}
        </div>
      </div>

      {mode === 'signup' && (
        <div>
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">
            Username
          </label>
          <div className="mt-1">
            <input
              id="username"
              type="text"
              value={formData.username}
              onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
              className={`block w-full rounded-md shadow-sm ${
                errors.username 
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            {errors.username && (
              <p className="mt-1 text-sm text-red-600">{errors.username}</p>
            )}
          </div>
        </div>
      )}

      {mode !== 'reset' && (
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div className="mt-1 relative">
            <input
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
              className={`block w-full rounded-md shadow-sm pr-10 ${
                errors.password 
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center"
            >
              {showPassword ? (
                <EyeOff className="h-5 w-5 text-gray-400" />
              ) : (
                <Eye className="h-5 w-5 text-gray-400" />
              )}
            </button>
          </div>
          {errors.password && (
            <p className="mt-1 text-sm text-red-600">{errors.password}</p>
          )}
        </div>
      )}

      {mode === 'signup' && (
        <div>
          <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
            Confirm Password
          </label>
          <div className="mt-1">
            <input
              id="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
              className={`block w-full rounded-md shadow-sm ${
                errors.confirmPassword 
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500' 
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
              }`}
            />
            {errors.confirmPassword && (
              <p className="mt-1 text-sm text-red-600">{errors.confirmPassword}</p>
            )}
          </div>
        </div>
      )}

      <div>
        <button
          type="submit"
          disabled={loading}
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <div className="flex items-center">
              <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
              <span className="ml-2">
                {mode === 'signup' ? 'Creating account...' : mode === 'login' ? 'Signing in...' : 'Sending reset link...'}
              </span>
            </div>
          ) : (
            mode === 'signup' ? 'Create Account' : mode === 'login' ? 'Sign In' : 'Send Reset Link'
          )}
        </button>
      </div>

      <div className="flex items-center justify-between text-sm">
        {mode === 'reset' ? (
          <button
            type="button"
            onClick={() => onModeChange('login')}
            className="text-indigo-600 hover:text-indigo-500"
          >
            Back to sign in
          </button>
        ) : (
          <button
            type="button"
            onClick={() => onModeChange('reset')}
            className="text-indigo-600 hover:text-indigo-500"
          >
            Forgot password?
          </button>
        )}
      </div>
    </form>
  );
}