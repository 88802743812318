import React, { useState, useEffect } from 'react';
import { X, Check, Loader2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

type Props = {
  videoId: string;
  videoType: 'long-form' | 'short-form';
  onClose: () => void;
  onApprove: () => void;
};

type ContentOption = {
  id: string;
  title?: string;
  description?: string;
  text_overlay?: string;
  is_approved: boolean;
  selected?: boolean;
};

export function ReviewModal({ videoId, videoType, onClose, onApprove }: Props) {
  const [titles, setTitles] = useState<ContentOption[]>([]);
  const [descriptions, setDescriptions] = useState<ContentOption[]>([]);
  const [thumbnailTexts, setThumbnailTexts] = useState<ContentOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [approving, setApproving] = useState(false);

  useEffect(() => {
    loadContentOptions();
  }, [videoId]);

  const loadContentOptions = async () => {
    try {
      setLoading(true);
      
      // Load all content options
      const { data: titleData, error: titleError } = await supabase
        .from('video_titles')
        .select('*')
        .eq('video_id', videoId)
        .eq('video_type', videoType);

      if (titleError) throw titleError;

      const { data: descData, error: descError } = await supabase
        .from('video_descriptions')
        .select('*')
        .eq('video_id', videoId)
        .eq('video_type', videoType);

      if (descError) throw descError;

      const { data: textData, error: textError } = await supabase
        .from('video_thumbnails')
        .select('*')
        .eq('video_id', videoId)
        .eq('video_type', videoType);

      if (textError) throw textError;

      // If there's no content, close the modal
      if (!titleData?.length && !descData?.length && !textData?.length) {
        toast.info('No content to review');
        onClose();
        return;
      }

      setTitles(titleData?.map(t => ({ ...t, selected: false })) || []);
      setDescriptions(descData?.map(d => ({ ...d, selected: false })) || []);
      setThumbnailTexts(textData?.map(t => ({ ...t, selected: false })) || []);
    } catch (error) {
      console.error('Error loading content options:', error);
      toast.error('Failed to load content options');
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (type: string, id: string) => {
    switch (type) {
      case 'title':
        setTitles(prev => prev.map(t => 
          t.id === id ? { ...t, selected: !t.selected } : t
        ));
        break;
      case 'description':
        setDescriptions(prev => prev.map(d => 
          d.id === id ? { ...d, selected: !d.selected } : d
        ));
        break;
      case 'thumbnail':
        setThumbnailTexts(prev => prev.map(t => 
          t.id === id ? { ...t, selected: !t.selected } : t
        ));
        break;
    }
  };

  const handleApprove = async () => {
    const selectedTitles = titles.filter(t => t.selected);
    const selectedDescriptions = descriptions.filter(d => d.selected);
    const selectedThumbnails = thumbnailTexts.filter(t => t.selected);

    if (selectedTitles.length === 0 || selectedDescriptions.length === 0 || selectedThumbnails.length === 0) {
      toast.error('Please select at least one option from each category');
      return;
    }

    setApproving(true);
    try {
      // First update the selected items to approved
      await Promise.all([
        supabase
          .from('video_titles')
          .update({ is_approved: true })
          .in('id', selectedTitles.map(t => t.id)),
        supabase
          .from('video_descriptions')
          .update({ is_approved: true })
          .in('id', selectedDescriptions.map(d => d.id)),
        supabase
          .from('video_thumbnails')
          .update({ is_approved: true })
          .in('id', selectedThumbnails.map(t => t.id))
      ]);

      // Then delete the unselected items
      const unselectedTitleIds = titles.filter(t => !t.selected).map(t => t.id);
      const unselectedDescIds = descriptions.filter(d => !d.selected).map(d => d.id);
      const unselectedThumbIds = thumbnailTexts.filter(t => !t.selected).map(t => t.id);

      await Promise.all([
        unselectedTitleIds.length > 0 && supabase
          .from('video_titles')
          .delete()
          .in('id', unselectedTitleIds),
        unselectedDescIds.length > 0 && supabase
          .from('video_descriptions')
          .delete()
          .in('id', unselectedDescIds),
        unselectedThumbIds.length > 0 && supabase
          .from('video_thumbnails')
          .delete()
          .in('id', unselectedThumbIds)
      ]);

      // Update video content review status
      const tableName = videoType === 'long-form' ? 'long_form_videos' : 'short_form_videos';
      await supabase
        .from(tableName)
        .update({ content_review_status: 'reviewed' })
        .eq('id', videoId);

      toast.success('Content approved successfully');
      onApprove();
      onClose();
    } catch (error) {
      console.error('Error approving content:', error);
      toast.error('Failed to approve content');
    } finally {
      setApproving(false);
    }
  };

  const renderOption = (option: ContentOption, type: string) => {
    const content = option.title || option.description || option.text_overlay;
    
    return (
      <div 
        key={option.id} 
        className={`flex items-center space-x-3 p-4 rounded-lg cursor-pointer transition-colors ${
          option.selected 
            ? 'bg-indigo-50 border-2 border-indigo-500' 
            : 'bg-gray-50 hover:bg-gray-100 border-2 border-transparent'
        }`}
        onClick={() => handleSelect(type, option.id)}
      >
        <div className={`flex-shrink-0 w-5 h-5 rounded ${
          option.selected 
            ? 'border-2 border-indigo-500 bg-indigo-500' 
            : 'border-2 border-gray-300'
        }`}>
          {option.selected && (
            <Check className="h-4 w-4 text-white" />
          )}
        </div>
        <p className="flex-1 text-gray-900">{content}</p>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-6 max-w-4xl w-full">
          <div className="flex justify-center py-8">
            <Loader2 className="h-8 w-8 text-indigo-600 animate-spin" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Review & Approve Content</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="space-y-8">
          {/* Title Options */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-md font-medium text-gray-900">Select Titles</h3>
              <span className="text-sm text-gray-500">
                {titles.filter(t => t.selected).length} selected
              </span>
            </div>
            <div className="space-y-3">
              {titles.length === 0 ? (
                <p className="text-center text-gray-500 py-4">No title options to review</p>
              ) : (
                titles.map(title => renderOption(title, 'title'))
              )}
            </div>
          </div>

          {/* Description Options */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-md font-medium text-gray-900">Select Descriptions</h3>
              <span className="text-sm text-gray-500">
                {descriptions.filter(d => d.selected).length} selected
              </span>
            </div>
            <div className="space-y-3">
              {descriptions.length === 0 ? (
                <p className="text-center text-gray-500 py-4">No description options to review</p>
              ) : (
                descriptions.map(desc => renderOption(desc, 'description'))
              )}
            </div>
          </div>

          {/* Thumbnail Text Options */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-md font-medium text-gray-900">Select Thumbnail Texts</h3>
              <span className="text-sm text-gray-500">
                {thumbnailTexts.filter(t => t.selected).length} selected
              </span>
            </div>
            <div className="space-y-3">
              {thumbnailTexts.length === 0 ? (
                <p className="text-center text-gray-500 py-4">No thumbnail text options to review</p>
              ) : (
                thumbnailTexts.map(text => renderOption(text, 'thumbnail'))
              )}
            </div>
          </div>

          {/* Approve Button */}
          <div className="flex justify-end pt-4">
            <button
              onClick={handleApprove}
              disabled={approving || 
                !titles.some(t => t.selected) || 
                !descriptions.some(d => d.selected) || 
                !thumbnailTexts.some(t => t.selected)
              }
              className="flex items-center space-x-2 px-6 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {approving ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Approving...</span>
                </>
              ) : (
                <>
                  <Check className="h-4 w-4" />
                  <span>Approve Selected</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}