// Helper to get environment variables from multiple sources
export function getEnvVar(key: string): string {
  // First check window.ENV (Bolt environment variables)
  if (window.ENV?.[key]) {
    return window.ENV[key];
  }

  // Then check import.meta.env (Vite development)
  const envValue = import.meta.env[key];
  if (typeof envValue === 'string') {
    return envValue;
  }

  // Return empty string if not found
  return '';
}

// Function to validate required environment variables
export function validateEnvVars(requiredVars: string[]): void {
  const missing = requiredVars.filter(key => !getEnvVar(key));
  if (missing.length > 0) {
    throw new Error(`Missing required environment variables: ${missing.join(', ')}`);
  }
}